import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { Tariff } from '../../../../../types/api/tariffs';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import ViewTariffContent from './ViewTariffContent';

type Props = {
  visible: boolean;
  data: Tariff | any;
  isLoading: boolean;
  onEdit?: () => void;
  onHide: () => void;
};

function ViewTariffDetailsDialog({
  visible,
  data,
  isLoading,
  onEdit,
  onHide,
}: Props) {
  const { t } = useTranslation();
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const dialogFooter = !isLoading && (
    <>
      {onEdit && (
        <Button label={t('Edit')} className="p-button-text" onClick={onEdit} />
      )}
      <Button label={t('Close')} onClick={() => onHide()} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={
        isLoading
          ? t('Loading...')
          : t('Tariff {{name}}', { name: data?.name ?? '' })
      }
      footer={dialogFooter}
      dismissableMask
      blockScroll
      maximizable
      maximized={isOnMobile}
      className="view-order-dialog" // class from order for same css style
    >
      {isLoading ? <DialogSpinner /> : <ViewTariffContent data={data} />}
    </Dialog>
  );
}

export default ViewTariffDetailsDialog;
