import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import useHaveValuesChanged from '../../../../hooks/useHaveValuesChanged';
import useSearchQueryDropdownParam from '../../../../hooks/useSearchQueryDropdownParam';
import { MunicipalityCollection } from '../../../../types/api/municipalities';
import * as warehousesGuards from '../../../../utils/constants/auth/warehouses';
import { getSearchQueryParam } from '../../../../utils/helpers/searchQuery';
import FiltersCounter from '../../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const municipalityFilterGuard = useEndpointGuard(
    warehousesGuards.municipalityFilter
  );
  const placesFilterGuard = useEndpointGuard(warehousesGuards.placesFilter);
  const location = useLocation();
  const [mestoId, setMestoId] = useState<string>(
    () => getSearchQueryParam(location.search, 'mesto_id') ?? ''
  );
  const [opstinaId, setOpstinaId] = useState<string>(
    () => getSearchQueryParam(location.search, 'opstina_id') ?? ''
  );

  const { data: municipalities, isLoading: isMunicipalitiesLoading } =
    useAxiosHook<MunicipalityCollection>('/municipalities');

  const municipalityFilterOptions = useMemo(
    () =>
      municipalities?.map((municipality) => ({
        label: municipality.ime,
        value: municipality.id,
      })) ?? [],
    [municipalities]
  );

  const { data: places, isLoading: isPlacesLoading } = useAxiosHook<any>(
    '/places/municipalities',
    {
      skipWhen: !placesFilterGuard,
    }
  );

  const placeFilterOptions = useMemo(
    () =>
      places?.map((place: any) => ({
        label: place.ime,
        value: place.id,
      })) ?? [],
    [places]
  );

  const filtersArr = useMemo(
    () => [
      {
        mestoId,
        opstinaId,
      },
    ],
    [mestoId, opstinaId]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [opstinaId, mestoId, setPage]);

  useSearchQueryDropdownParam(
    'mesto_id',
    mestoId,
    setMestoId,
    placeFilterOptions
  );

  useSearchQueryDropdownParam(
    'opstina_id',
    opstinaId,
    setOpstinaId,
    municipalityFilterOptions
  );

  const httpFiltersObj = useMemo(
    () => ({
      mesto_id: mestoId,
      opstina_id: opstinaId,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [mestoId, opstinaId, haveFiltersChanged, page, limit]
  );

  const basicFiltersActiveFilterCount = Object.values({
    mestoId,
    opstinaId,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          {municipalityFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="filter_name">{t('Municipality')}</label>
              <Dropdown
                id="filter_municipality"
                value={opstinaId}
                onChange={(e) => setOpstinaId(e.value)}
                disabled={isMunicipalitiesLoading}
                options={municipalityFilterOptions}
                filter
                filterPlaceholder={t('Search')}
                showClear
              />
            </div>
          )}
          {placesFilterGuard && (
            <div className="sidebar_filter">
              <label htmlFor="filter_name">{t('Place')}</label>
              <Dropdown
                id="filter_place"
                value={mestoId}
                onChange={(e) => setMestoId(e.value)}
                disabled={isPlacesLoading}
                options={placeFilterOptions}
                filter
                filterPlaceholder={t('Search')}
                showClear
              />
            </div>
          )}
        </AccordionTab>
      </Accordion>
    ),
    [
      mestoId,
      opstinaId,
      placeFilterOptions,
      municipalityFilterOptions,
      basicFiltersActiveFilterCount,
      isMunicipalitiesLoading,
      isPlacesLoading,
      municipalityFilterGuard,
      placesFilterGuard,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setMestoId('');
    setOpstinaId('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
