import dayjs from 'dayjs';
import { TabPanel, TabView } from 'primereact/tabview';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../../../context/ToastContext';
import { IntBool } from '../../../../../enums/booleans';
import { FileTypes } from '../../../../../enums/files';
import { ReconciliationStatus } from '../../../../../enums/reconciliations';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useDialogVisibility from '../../../../../hooks/useDialogVisibility';
import { useEndpointGuard } from '../../../../../hooks/useEndpointGuard';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePageTitle from '../../../../../hooks/usePageTitle';
import useSearchQueryParam from '../../../../../hooks/useSearchQueryParam';
import useTableState from '../../../../../hooks/useTableState';
import useTabViewRouting from '../../../../../hooks/useTabViewRouting';
import useToastMessage from '../../../../../hooks/useToastMessage';
import {
  DeliveredShipmentReconciliationCollection,
  PickedUpShipmentReconciliationCollection,
  ReconciliationResource,
  UpdateReconciliationResourceRequestPayload,
} from '../../../../../types/api/reconciliations';
import { WorkOrderResource } from '../../../../../types/api/workOrders';
import { ExportReconciliationCandidatesToExcelQueryParams } from '../../../../../types/reports/reconciliationCandidates';
import { ReconciliationDocumentResourceQueryParams } from '../../../../../types/reports/reconciliationDocument';
import { EntityIdRouteParams } from '../../../../../types/routing';
import { Unpacked } from '../../../../../types/util';
import { RoutePaths } from '../../../../../utils/constants/routePaths';
import { downloadFile, getFileName } from '../../../../../utils/helpers/files';
import {
  dateFormat,
  httpDateFormat,
} from '../../../../../utils/helpers/formatting';
import { queryString } from '../../../../../utils/helpers/http';
import { getSearchQueryParam } from '../../../../../utils/helpers/searchQuery';
import Grid from '../../../../Grid/Grid';
import MainContent from '../../../../Grid/MainContent';
import SidePanels from '../../../../Grid/SidePanels';
import Barcode from '../../../../Sidebar/Barcode/Barcode';
import HeaderPages from '../../../Components/HeaderPages/HeaderPages';
import CreateEditDialog from '../../../Orders/Dialogs/CreateEdit/CreateEditDialog';
import ViewActiveOrderDialog from '../../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../../../Orders/Orders.functions';
import { QueryParams as CourierReconciliationPageQueryParams } from '../../CourierReconciliation.types';
import Actions from './components/sidebar/Actions';
import Info from './components/sidebar/Info';
import Legend from './components/sidebar/Legend';
import Stats from './components/sidebar/Stats';
import useHandleReconcileResponse from './hooks/useHandleReconcileResponse';
import { tabPaths } from './ShipmentReconciliation.functions';
import { updateGuard } from './ShipmentReconciliation.guards';
import {
  ActiveTabs,
  DataRow,
  ReconcileShipments,
} from './ShipmentReconciliation.types';
import DeliveredTable from './Tabs/DeliveredTable/DeliveredTable';
import { reconciliationListsCourierPickedUp } from './Tabs/DeliveredTable/DeliveredTable.functions';
import PickedUpTable from './Tabs/PickedUpTable/PickedUpTable';
import { reconciliationListsCourierDelivered } from './Tabs/PickedUpTable/PickedUpTable.functions';

function ShipmentReconciliation(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<EntityIdRouteParams>();
  const [deliveredShipments, setDeliveredShipments] = useState<DataRow[]>([]);
  const [pickedUpShipments, setPickedUpShipments] = useState<DataRow[]>([]);

  function getBackLink() {
    const date = getSearchQueryParam(history.location.search, 'date');
    const courier = getSearchQueryParam(history.location.search, 'courier');
    const status = getSearchQueryParam(history.location.search, 'status');
    const work_order = getSearchQueryParam(
      history.location.search,
      'work_order'
    );
    return (
      RoutePaths.CourierReconciliations +
      queryString<CourierReconciliationPageQueryParams>({
        date,
        courier,
        status,
        work_order,
      })
    );
  }

  function handleGoBack() {
    return history.replace(getBackLink());
  }

  const {
    data: reconciliationData,
    error: reconciliationError,
    // isLoading: reconciliationDataIsLoading,
    // reload: reconciliationDataReload,
  } = useAxiosHook<ReconciliationResource>(`/reconciliations/${id}`);

  const { data: workOrderData, error: workOrderError } =
    useAxiosHook<WorkOrderResource>(
      `/work-orders/${reconciliationData?.work_order_id}`,
      { skipWhen: reconciliationData?.work_order_id === undefined }
    );

  useToastMessage(undefined, reconciliationError || workOrderError, {
    error: {
      summary: t('An error occured while reading reconciliation data.'),
      callback: handleGoBack,
    },
  });

  const {
    isVisible: isEditDialogVisible,
    show: showEditDialog,
    hide: hideEditDialog,
  } = useDialogVisibility();

  const {
    isVisible: isViewActiveOrderDialog,
    show: showViewActiveOrderDialog,
    hide: hideViewActiveOrderDialog,
  } = useDialogVisibility();

  const pageTitle =
    t('Shipment Reconciliation') +
    (!!workOrderData ? ' - ' + workOrderData.courier_full_name : '');

  usePageTitle(pageTitle);

  const canUpdate =
    useEndpointGuard(updateGuard) &&
    reconciliationData?.status_id !== ReconciliationStatus.Approved;

  const date = workOrderData?.date ?? '';
  const courierId = workOrderData?.courier_id ?? '';

  const { bottomRightToastRef } = useContext(ToastContext);

  const [activeTabIndex, setActiveTabIndex] = useTabViewRouting(tabPaths) as [
    ActiveTabs,
    Dispatch<SetStateAction<ActiveTabs>>
  ];

  useSearchQueryParam('activeTabIndex', String(activeTabIndex));

  const [barcodeValue, setBarcodeValue] = useState<string>('');
  const [barcodeError, setBarcodeError] = useState<boolean>(false);

  const deliveredTableInstance = useTableState<
    Unpacked<DeliveredShipmentReconciliationCollection>
  >(reconciliationListsCourierPickedUp);

  const pickedUpTableInstance = useTableState<
    Unpacked<PickedUpShipmentReconciliationCollection>
  >(reconciliationListsCourierDelivered);

  const {
    data: updateData,
    error: updateError,
    isLoading: isUpdateLoading,
    reload: updateReload,
    response: updateResponse,
  } = useAxiosHook<ReconcileShipments>();

  useHandleReconcileResponse(updateResponse, updateData, updateError);

  function handleSaveBtnClick() {
    const data: UpdateReconciliationResourceRequestPayload = {
      orders: [
        ...(deliveredTableInstance?.selectionMultiple.length > 0
          ? deliveredTableInstance.selectionMultiple
              .filter((item) => item.is_reconciled === IntBool.False)
              .map((r) => ({
                assignment_id: r.delivery_order_id,
                amount: r.cash_delivery,
              }))
          : []),
        ...(pickedUpTableInstance?.selectionMultiple.length > 0
          ? pickedUpTableInstance.selectionMultiple
              .filter((item) => item.is_reconciled === IntBool.False)
              .map((r) => ({
                assignment_id: r.delivery_order_id,
                amount: r.cash_pickup,
              }))
          : []),
      ],
    };

    updateReload({
      url: `/reconciliations/${id}/orders`,
      method: 'PUT',
      data,
    });
  }

  const handleExportToExcelBtnClick = useCallback(
    (type: ExportReconciliationCandidatesToExcelQueryParams['type']) => {
      const queryStr =
        queryString<ExportReconciliationCandidatesToExcelQueryParams>({
          date: httpDateFormat(date),
          courier_id: courierId,
          type,
        });

      downloadFile(
        `${process.env.REACT_APP_REPORT_URL}/reconciliation-candidates${queryStr}`,
        getFileName(
          t('ShipmentReconciliation'),
          [
            activeTabIndex === 0 ? 'Delivered' : 'PickedUp',
            dayjs(date).format('YYYYMMDD'),
          ],
          true
        ),
        FileTypes.XLSX,
        bottomRightToastRef
      );
    },
    [activeTabIndex, bottomRightToastRef, courierId, date, t]
  );

  /* <Parameters<typeof Stats>[0]> */
  const stats = useMemo(() => {
    const reconciledDelivered = deliveredShipments?.filter(
      (s) => s.is_reconciled === IntBool.True
    );

    const reconciledPickedUp = pickedUpShipments?.filter(
      (s) => s.is_reconciled === IntBool.True
    );

    // Reconciled delivered
    const currentReconciledDelivered = reconciledDelivered?.length ?? 0;
    const totalReconciledDelivered = deliveredShipments?.length ?? 0;

    // Reconciled picked up
    const currentReconciledPickedUp = reconciledPickedUp?.length ?? 0;

    const totalReconciledPickedUp = pickedUpShipments?.length ?? 0;

    // Redemption
    const totalTotalCashRedemption = deliveredShipments?.reduce(
      (prev, current) => prev + parseFloat(current.redemption),
      0
    );

    const currentTotalCashRedemption =
      deliveredShipments?.reduce(
        (prev, current) =>
          prev + parseFloat(current.reconciled_redemption_cash),
        0
      ) ?? 0;

    // Total cash delivered
    const totalTotalCashDelivered = deliveredShipments?.reduce(
      (prev, current) => prev + parseFloat(current.cash_delivery),
      0
    );

    const currentTotalCashDelivered =
      deliveredShipments?.reduce(
        (prev, current) => prev + parseFloat(current.reconciled_postage_cash),
        0
      ) ?? 0;

    // Total cash picked up
    const totalTotalCashPickedUp = pickedUpShipments?.reduce(
      (prev, current) => prev + parseFloat(current.cash_pickup),
      0
    );

    const currentTotalCashPickedUp =
      pickedUpShipments?.reduce(
        (prev, current) => prev + parseFloat(current.reconciled_postage_cash),
        0
      ) ?? 0;

    // Total cash
    const totalCash =
      currentTotalCashRedemption +
      currentTotalCashDelivered +
      currentTotalCashPickedUp;

    const currentReconciledCash =
      currentTotalCashDelivered +
      currentTotalCashPickedUp +
      currentTotalCashRedemption;

    // Total reconciled cash
    const totalReconciledCash =
      totalTotalCashRedemption +
      totalTotalCashDelivered +
      totalTotalCashPickedUp;

    // Total fiscalized amount
    const totalFiscalizedAmount = [
      ...deliveredShipments,
      ...pickedUpShipments,
    ].reduce((prev, current) => prev + parseFloat(current?.bill), 0);

    return {
      currentReconciledDelivered,
      totalReconciledDelivered,
      currentReconciledPickedUp,
      totalReconciledPickedUp,
      currentTotalCashRedemption,
      totalTotalCashRedemption,
      currentTotalCashDelivered,
      totalTotalCashDelivered,
      currentTotalCashPickedUp,
      totalTotalCashPickedUp,
      totalCash,
      currentReconciledCash,
      totalReconciledCash,
      totalFiscalizedAmount,
    };
  }, [deliveredShipments, pickedUpShipments]);

  const deliveredTableExportToExcel = useCallback(() => {
    handleExportToExcelBtnClick('delivered');
  }, [handleExportToExcelBtnClick]);

  const pickedUpTableExportToExcel = useCallback(() => {
    handleExportToExcelBtnClick('picked-up');
  }, [handleExportToExcelBtnClick]);

  const onClickEditDialog = (row: DataRow) => {
    reloadOrder({ url: `/orders/${row.order_id}` });
    if (canUpdate) {
      showEditDialog();
      return;
    }
    showViewActiveOrderDialog();
  };

  function handleReconciliationPrint() {
    if (!id) {
      return;
    }

    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/reconciliation-document/pdf` +
        queryString<ReconciliationDocumentResourceQueryParams>({
          reconciliation_id: id,
        }),
      getFileName(t('Reconciliation'), [
        workOrderData?.id !== undefined ? String(workOrderData?.id) : '',
        workOrderData?.courier_full_name ?? '',
        workOrderData?.date ? httpDateFormat(workOrderData.date) : '',
      ]),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  const {
    data: orderData,
    isLoading: isOrderLoading,
    error: orderError,
    reload: reloadOrder,
  } = useAxiosHook<SingleOrder>();

  useToastMessage(orderData, orderError, {
    error: {
      summary: t('An error occured while reading order data.'),
    },
  });

  const isSmallScreen = useMediaQuery('(max-width: 1000px)');

  return (
    <div className="page shipment-reconciliation">
      <HeaderPages
        title={pageTitle}
        subtitle={`${t('Register shipment for reconciliation')}
            ${!!workOrderData && ' - ' + dateFormat(workOrderData.date)}`}
        goBackTitle={t('Courier Reconciliation')}
        goBackTo={getBackLink()}
      />

      <CreateEditDialog
        visible={isEditDialogVisible}
        data={orderData}
        isEditDialog
        isLoading={isOrderLoading}
        reload={reloadOrder}
        onHide={hideEditDialog}
        isSmallScreen={isSmallScreen}
      />

      <ViewActiveOrderDialog
        visible={isViewActiveOrderDialog}
        data={orderData}
        isLoading={isOrderLoading}
        onHide={hideViewActiveOrderDialog}
      />

      <Grid>
        <SidePanels>
          <Info workOrder={workOrderData} reconciliation={reconciliationData} />
          <Legend />
          <Barcode
            value={barcodeValue}
            setValue={setBarcodeValue}
            hasError={barcodeError}
            setHasError={setBarcodeError}
            disabled={isUpdateLoading}
          />
          <Actions
            onSaveBtnClick={handleSaveBtnClick}
            isSaveBtnVisible={canUpdate}
            isSaveBtnDisabled={
              isUpdateLoading ||
              (deliveredTableInstance.selectionMultiple.filter(
                (item) => item.is_reconciled === IntBool.False
              ).length === 0 &&
                pickedUpTableInstance.selectionMultiple.filter(
                  (item) => item.is_reconciled === IntBool.False
                ).length === 0)
            }
            onPrintBtnClick={handleReconciliationPrint}
            isUpdateLoading={isUpdateLoading}
          />
          <Stats {...stats} />
        </SidePanels>

        <MainContent>
          <TabView
            activeIndex={activeTabIndex}
            onTabChange={(e) => {
              setActiveTabIndex(e.index);
            }}
            className="table-tabview"
          >
            <TabPanel header={t('Delivered.THEM')}>
              <DeliveredTable
                tableInstance={deliveredTableInstance}
                reconciliationData={reconciliationData}
                workOrderData={workOrderData}
                shouldDisplayData={true}
                exportToExcel={deliveredTableExportToExcel}
                setDeliveredShipments={setDeliveredShipments}
                onClickEditDialog={onClickEditDialog}
              />
            </TabPanel>

            <TabPanel header={t('Picked up.THEM')}>
              <PickedUpTable
                tableInstance={pickedUpTableInstance}
                reconciliationData={reconciliationData}
                workOrderData={workOrderData}
                shouldDisplayData={false}
                exportToExcel={pickedUpTableExportToExcel}
                setPickedUpShipments={setPickedUpShipments}
                onClickEditDialog={onClickEditDialog}
              />
            </TabPanel>
          </TabView>
        </MainContent>
      </Grid>
    </div>
  );
}

export default ShipmentReconciliation;
