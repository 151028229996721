import { Redirect, Switch } from 'react-router-dom';

import * as clientsGuards from '../../utils/constants/auth/clients';
import * as courierLiabilitiesGuards from '../../utils/constants/auth/courierLiabilities';
import * as courierMapGuards from '../../utils/constants/auth/courierMap';
import * as courierPickupAssignmentGuards from '../../utils/constants/auth/courierPickupAssignment';
import * as couriersCashGuards from '../../utils/constants/auth/couriersCash';
import * as courierShipmentAssignmentGuards from '../../utils/constants/auth/courierShipmentAssignment';
import * as courierShipmentReceptionGuards from '../../utils/constants/auth/courierShipmentReception';
import * as customerOrdersGuards from '../../utils/constants/auth/customerOrders';
import * as customerShipmentReceptionGuards from '../../utils/constants/auth/customerShipmentReception';
import * as dashboardGuards from '../../utils/constants/auth/dashboard';
import * as deviceManagementGuards from '../../utils/constants/auth/deviceManagement';
import * as employeesGuards from '../../utils/constants/auth/employees';
import * as handOverToCustomerGuards from '../../utils/constants/auth/handOverToCustomer';
import * as hubShipmentDistributionGuards from '../../utils/constants/auth/hubShipmentDistribution';
import * as hubShipmentReceptionGuards from '../../utils/constants/auth/hubShipmentReception';
import * as hubsReportsGuards from '../../utils/constants/auth/hubsReports';
import * as importedListsGuards from '../../utils/constants/auth/importedLists';
import * as invoicesGuards from '../../utils/constants/auth/invoices';
import * as listTypesGuards from '../../utils/constants/auth/listTypes';
import * as oAuthClients from '../../utils/constants/auth/oAuthClients';
import * as ordersGuards from '../../utils/constants/auth/orders';
import * as priceListsGuards from '../../utils/constants/auth/priceLists';
import * as pricesGuards from '../../utils/constants/auth/prices';
import * as receptionAndDeliveryMapGuards from '../../utils/constants/auth/receptionAndDeliveryMap';
import * as regionsGuards from '../../utils/constants/auth/regions';
import * as settingsGuards from '../../utils/constants/auth/settings';
import * as trackShipmentByLocationGuards from '../../utils/constants/auth/trackShipmentsByLocation';
import * as transferCourierToCourierGuards from '../../utils/constants/auth/transferCourierToCourier';
import * as vehiclesGuards from '../../utils/constants/auth/vehicles';
import * as warehousesGuards from '../../utils/constants/auth/warehouses';
import { RoutePaths } from '../../utils/constants/routePaths';
import BulkOrders from '../AdminPages/BulkOrders/BulkOrders';
import Clients from '../AdminPages/Clients/Clients';
import CreateWarrants from '../AdminPages/COD/Create/CreateWarrants';
import Warrants from '../AdminPages/COD/Warrants/Warrants';
import CourierCompensation from '../AdminPages/CourierCompensation/CourierCompensation';
import CourierLiabilities from '../AdminPages/CourierLiabilities/CourierLiabilities';
import CourierMap from '../AdminPages/CourierMap/CourierMap';
import CourierPickupAssignment from '../AdminPages/CourierPickupAssignment/CourierPickupAssignment';
import CourierReconciliation from '../AdminPages/CourierReconciliation/CourierReconciliation';
import * as courierReconciliationGuards from '../AdminPages/CourierReconciliation/CourierReconciliation.guards';
import ShipmentReconciliation from '../AdminPages/CourierReconciliation/views/ShipmentReconciliation/ShipmentReconciliation';
import CouriersCash from '../AdminPages/CouriersCash/CouriersCash';
import Dashboard from '../AdminPages/Dashboard/Dashboard';
import DeviceManagement from '../AdminPages/DeviceManagement/DeviceManagement';
import Employees from '../AdminPages/Employees/Employees';
import InvoicesCreation from '../AdminPages/Invoices/InvoicesCreation/InvoicesCreation';
import InvoicesPreview from '../AdminPages/Invoices/InvoicesPreview/InvoicesPreview';
import ListTypes from '../AdminPages/ListTypes/ListTypes';
import Logs from '../AdminPages/Logs/Logs';
import OAuthClients from '../AdminPages/OAuthClients/OAuthClients';
import DeletedOrders from '../AdminPages/Orders/DeletedOrders/DeletedOrders';
import History from '../AdminPages/Orders/History/History';
import Orders from '../AdminPages/Orders/Orders';
import PriceLists from '../AdminPages/PriceLists/PriceLists';
import Prices from '../AdminPages/Prices/Prices';
import Profile from '../AdminPages/Profile/Profile';
import ReceptionAndDeliveryMap from '../AdminPages/ReceptionAndDeliveryMap/ReceptionAndDeliveryMap';
import Regions from '../AdminPages/Regions/Regions';
import Cashdesk from '../AdminPages/Reports/Cashdesk/Cashdesk';
import CustomerEarnings from '../AdminPages/Reports/CustomerEarnings/CustomerEarnings';
import CustomerOrders from '../AdminPages/Reports/CustomerOrders/CustomerOrders';
import RoutesPage from '../AdminPages/Routes/Routes';
import Settings from '../AdminPages/Settings/Settings';
import SmartPosTerminals from '../AdminPages/SmartPosTerminals/SmartPosTerminals';
import Tariffs from '../AdminPages/Tariffs/Tariffs';
import Vehicles from '../AdminPages/Vehicles/Vehicles';
import CourierShipmentAssignment from '../AdminPages/Warehouse/Assignment/Courier/CourierShipmentAssignment';
import HubShipmentDistribution from '../AdminPages/Warehouse/Assignment/Hub/HubShipmentDistribution';
import HandoverToCustomer from '../AdminPages/Warehouse/HandoverToCustomer/HandoverToCustomer';
import HubsReports from '../AdminPages/Warehouse/HubsReports/HubsReports';
import CourierShipmentReception from '../AdminPages/Warehouse/Reception/Courier/CourierShipmentReception';
import CustomerShipmentReception from '../AdminPages/Warehouse/Reception/Customer/CustomerShipmentReception';
import HubShipmentReception from '../AdminPages/Warehouse/Reception/Hub/HubShipmentReception';
import TransferCourierToCourier from '../AdminPages/Warehouse/Reception/TransferCourierToCourier/TransferCourierToCourier';
import TrackShipmentsByLocation from '../AdminPages/Warehouse/TrackShipmentsByLocation/TrackShipmentsByLocation';
import WarehousesList from '../AdminPages/Warehouse/WarehousesList/WarehousesList';
import Welcome from '../AdminPages/Welcome/Welcome';
import Login from '../Authentication/Login/Login';
import Logout from '../Authentication/Logout/Logout';
import Page401 from '../ErrorPages/Page401';
import Page403 from '../ErrorPages/Page403';
import Page404 from '../ErrorPages/Page404';
import Page500 from '../ErrorPages/Page500';
import AuthRoute from './AuthRoute';
import DevOnlyRoute from './DevOnlyRoute';
import SentryRoute from './SentryRoute';
import UnauthRoute from './UnauthRoute';

function Routes(): JSX.Element {
  return (
    <Switch>
      <AuthRoute path={RoutePaths.Welcome} component={Welcome} />
      <AuthRoute
        path={RoutePaths.Dashboard}
        guard={dashboardGuards.routeGuard}
        component={Dashboard}
      />
      <AuthRoute
        path={RoutePaths.Clients}
        guard={clientsGuards.routeGuard}
        component={Clients}
      />
      <AuthRoute
        path={RoutePaths.Settings}
        guard={settingsGuards.routeGuard}
        component={Settings}
      />
      <AuthRoute
        path={[
          RoutePaths.ViewCourierLiability,
          RoutePaths.EditCourierLiability,
          RoutePaths.DeleteCourierLiability,
          RoutePaths.AddCourierLiability,
          RoutePaths.CourierLiabilities,
        ]}
        guard={courierLiabilitiesGuards.routeGuard}
        component={CourierLiabilities}
      />
      <AuthRoute
        path={RoutePaths.CouriersCash}
        guard={couriersCashGuards.routeGuard}
        component={CouriersCash}
      />

      {/* Orders */}
      <AuthRoute
        path={RoutePaths.BulkOrders}
        guard={importedListsGuards.routeGuard}
        component={BulkOrders}
      />
      <AuthRoute
        path={RoutePaths.ListTypes}
        guard={listTypesGuards.routeGuard}
        component={ListTypes}
      />
      <AuthRoute
        path={[
          RoutePaths.OrdersHistory,
          RoutePaths.PaymentOrderHistory,
          RoutePaths.DeliveryOrderHistory,
        ]}
        component={History}
        guard={ordersGuards.orderHistory}
      />
      <AuthRoute
        path={[RoutePaths.ViewDeletedOrder, RoutePaths.DeletedOrders]}
        component={DeletedOrders}
        guard={ordersGuards.deletedRouteGuard}
      />
      <AuthRoute
        path={[
          RoutePaths.ViewOrder,
          RoutePaths.EditOrder,
          RoutePaths.StatusOrder,
          RoutePaths.AddOrder,
          RoutePaths.Orders,
          RoutePaths.GroupOrder,
        ]}
        component={Orders}
        guard={ordersGuards.routeGuard}
      />

      {/* Logistics */}
      <AuthRoute
        path={RoutePaths.CourierPickupAssignment}
        guard={courierPickupAssignmentGuards.routeGuard}
        component={CourierPickupAssignment}
      />
      <AuthRoute
        path={[
          RoutePaths.ViewCourierShipmentReception,
          RoutePaths.CourierShipmentReceptions,
        ]}
        component={CourierShipmentReception}
        guard={courierShipmentReceptionGuards.routeGuard}
      />
      <AuthRoute
        path={[
          RoutePaths.ViewCourierShipmentAssignment,
          RoutePaths.CourierShipmentAssignments,
        ]}
        component={CourierShipmentAssignment}
        guard={courierShipmentAssignmentGuards.routeGuard}
      />
      <AuthRoute
        path={[
          RoutePaths.ViewHubShipmentReception,
          RoutePaths.HubShipmentReceptions,
        ]}
        component={HubShipmentReception}
        guard={hubShipmentReceptionGuards.routeGuard}
      />
      <AuthRoute
        path={[
          RoutePaths.ViewHubShipmentDistribution,
          RoutePaths.HubShipmentDistributions,
        ]}
        component={HubShipmentDistribution}
        guard={hubShipmentDistributionGuards.routeGuard}
      />
      <AuthRoute
        path={[
          RoutePaths.ViewTransferCourierToCourier,
          RoutePaths.TransfersCourierToCourier,
        ]}
        component={TransferCourierToCourier}
        guard={transferCourierToCourierGuards.routeGuard}
      />
      <AuthRoute
        path={[
          RoutePaths.AddCustomerShipmentReception,
          RoutePaths.AddSingleOrderCustomerShipmentReception,
          RoutePaths.AddBatchOrderCustomerShipmentReception,
          RoutePaths.CustomerShipmentReception,
        ]}
        component={CustomerShipmentReception}
        guard={customerShipmentReceptionGuards.routeGuard}
      />
      <AuthRoute
        path={RoutePaths.HandoverToCustomer}
        component={HandoverToCustomer}
        guard={handOverToCustomerGuards.routeGuard}
      />

      {/* Logistics Monitoring */}
      <AuthRoute
        path={RoutePaths.CourierMap}
        guard={courierMapGuards.routeGuard}
        component={CourierMap}
      />
      <AuthRoute
        path={RoutePaths.ReceptionAndDeliveryMap}
        guard={receptionAndDeliveryMapGuards.routeGuard}
        component={ReceptionAndDeliveryMap}
      />
      <AuthRoute
        path={[
          RoutePaths.TrackShipmentsByLocation,
          RoutePaths.ViewTrackedShipmentByLocation,
          RoutePaths.EditTrackedShipmentByLocation,
        ]}
        guard={trackShipmentByLocationGuards.routeGuard}
        component={TrackShipmentsByLocation}
      />
      <AuthRoute
        path={[RoutePaths.HubsReports]}
        guard={hubsReportsGuards.routeGuard}
        component={HubsReports}
      />

      {/* Finances */}
      <AuthRoute
        path={[
          RoutePaths.ShipmentReconciliations,
          RoutePaths.DeliveredShipmentReconciliations,
          RoutePaths.PickedUpShipmentReconciliations,
        ]}
        guard={courierReconciliationGuards.routeGuard}
        component={ShipmentReconciliation}
      />
      <AuthRoute
        path={[
          RoutePaths.AddCourierReconciliation,
          RoutePaths.CourierReconciliations,
        ]}
        guard={courierReconciliationGuards.routeGuard}
        component={CourierReconciliation}
      />
      <AuthRoute
        path={RoutePaths.AddInvoice}
        guard={invoicesGuards.invoicesRouteGuard}
        component={InvoicesCreation}
      />
      <AuthRoute
        path={RoutePaths.Invoices}
        guard={invoicesGuards.invoiceCandidatesRouteGuard}
        component={InvoicesPreview}
      />

      <AuthRoute path={RoutePaths.AddWarrant} component={CreateWarrants} />
      <AuthRoute
        path={[
          RoutePaths.ViewWarrant,
          RoutePaths.EmailWarrant,
          RoutePaths.Warrants,
        ]}
        component={Warrants}
      />

      {/* Reports */}
      <AuthRoute
        path={[RoutePaths.ViewCustomerOrder, RoutePaths.CustomerOrders]}
        guard={customerOrdersGuards.routeGuard}
        component={CustomerOrders}
      />

      {/* Organizational Management */}
      <AuthRoute
        path={RoutePaths.WarehousesList}
        guard={warehousesGuards.routeGuard}
        component={WarehousesList}
      />
      <AuthRoute
        path={RoutePaths.Employees}
        guard={employeesGuards.routeGuard}
        component={Employees}
      />
      <AuthRoute
        path={RoutePaths.DeviceManagement}
        guard={deviceManagementGuards.routeGuard}
        component={DeviceManagement}
      />
      <AuthRoute
        path={RoutePaths.SmartPosTerminals}
        guard={deviceManagementGuards.routeGuard}
        component={SmartPosTerminals}
      />
      <AuthRoute
        path={RoutePaths.Regions}
        guard={regionsGuards.routeGuard}
        component={Regions}
      />
      <AuthRoute
        path={[
          RoutePaths.ViewVehicle,
          RoutePaths.EditVehicle,
          RoutePaths.DeleteVehicle,
          RoutePaths.AddVehicle,
          RoutePaths.Vehicles,
        ]}
        guard={vehiclesGuards.routeGuard}
        component={Vehicles}
      />
      <AuthRoute
        path={[
          RoutePaths.ViewOAuthClient,
          RoutePaths.EditOAuthClient,
          RoutePaths.DeleteOAuthClient,
          RoutePaths.EmailOAuthClient,
          RoutePaths.AddOAuthClient,
          RoutePaths.OAuthClients,
        ]}
        guard={oAuthClients.routeGuard}
        component={OAuthClients}
      />

      {/* Billing */}
      <AuthRoute
        guard={pricesGuards.routeGuard}
        path={[
          RoutePaths.AddPrice,
          RoutePaths.EditPrice,
          RoutePaths.DeletePrice,
          RoutePaths.Prices,
        ]}
        component={Prices}
      />
      <AuthRoute
        guard={priceListsGuards.routeGuard}
        path={[
          RoutePaths.AddPriceList,
          RoutePaths.EditPriceList,
          RoutePaths.DeletePriceList,
          RoutePaths.PriceLists,
        ]}
        component={PriceLists}
      />
      <AuthRoute path={RoutePaths.Logs} component={Logs} />
      <AuthRoute
        path={[
          RoutePaths.AddTariff,
          RoutePaths.EditTariff,
          RoutePaths.DeleteTariff,
          RoutePaths.Tariffs,
        ]}
        component={Tariffs}
      />

      {/* Dev only */}
      <DevOnlyRoute
        path={[
          RoutePaths.ViewRoute,
          RoutePaths.EditRoute,
          RoutePaths.RoutesPage,
        ]}
        component={RoutesPage}
      />
      <DevOnlyRoute path={RoutePaths.Cashdesk} component={Cashdesk} />
      <DevOnlyRoute
        path={RoutePaths.CustomerEarnings}
        component={CustomerEarnings}
      />
      <DevOnlyRoute
        path={RoutePaths.CourierCompensation}
        component={CourierCompensation}
      />

      {/* General */}
      <AuthRoute path={RoutePaths.Logout} component={Logout} />
      <UnauthRoute path={RoutePaths.Login} component={Login} />
      <AuthRoute path={RoutePaths.Profile} component={Profile} />

      {/* Errors */}
      <SentryRoute path={RoutePaths.Error401} component={Page401} />
      <SentryRoute path={RoutePaths.Error403} component={Page403} />
      <SentryRoute path={RoutePaths.Error404} component={Page404} />
      <SentryRoute path={RoutePaths.Error500} component={Page500} />

      {/* Fallbacks */}
      <SentryRoute path="/" exact>
        <Redirect to="/welcome" />
      </SentryRoute>

      <SentryRoute path="*">
        <Redirect to="/404" />
      </SentryRoute>
    </Switch>
  );
}

export default Routes;
