import { Field, useFormikContext } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function Config({ section }) {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext();

  return (
    <div style={{ maxWidth: '480px' }}>
      <FieldWithErrors
        name={`${section}-NpoKlientId`}
        label={t('COD Client ID')}
      >
        <Field
          as={InputNumber}
          id={`${section}-NpoKlientId`}
          name={`${section}-NpoKlientId`}
          useGrouping={false}
          onChange={(e) => setFieldValue(`${section}-NpoKlientId`, e.value)}
          tooltip={t(
            'ID of the client with whom warrants for realized redemptions are distributed'
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-FakturaKlientId`}
        label={t('Invoice Client ID')}
      >
        <Field
          as={InputNumber}
          id={`${section}-FakturaKlientId`}
          name={`${section}-FakturaKlientId`}
          useGrouping={false}
          onChange={(e) => setFieldValue(`${section}-FakturaKlientId`, e.value)}
          tooltip={t(
            'ID of the client with whom invoices to users are distributed'
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-NvpKlientId`}
        label={t('NVP Client ID')}
      >
        <Field
          as={InputNumber}
          id={`${section}-NvpKlientId`}
          name={`${section}-NvpKlientId`}
          useGrouping={false}
          onChange={(e) => setFieldValue(`${section}-NvpKlientId`, e.value)}
          tooltip={t(
            'ID of the client with whom unrealized parcels are returned'
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-NppKlientId`}
        label={t('NPP Client ID')}
      >
        <Field
          as={InputNumber}
          id={`${section}-NppKlientId`}
          name={`${section}-NppKlientId`}
          useGrouping={false}
          onChange={(e) => setFieldValue(`${section}-NppKlientId`, e.value)}
          tooltip={t(
            'ID of the client with whom receipts for delivered parcels are distributed'
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-PovratenDokumentKlientId`}
        label={t('Return Document Client ID')}
      >
        <Field
          as={InputNumber}
          id={`${section}-PovratenDokumentKlientId`}
          name={`${section}-PovratenDokumentKlientId`}
          useGrouping={false}
          onChange={(e) =>
            setFieldValue(`${section}-PovratenDokumentKlientId`, e.value)
          }
          tooltip={t(
            'ID of the client who acts as a dispatcher when a return document is being distributed'
          )}
        />
      </FieldWithErrors>
    </div>
  );
}

export default Config;
