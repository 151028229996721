import * as Sentry from '@sentry/react';

import {
  getNotificationChannel,
  getNotificationType,
} from '../../../utils/constants/entities/notifications';
import { generateNotificationFields } from '../Clients/Dialogs/CreateEditDialog/CreateEditDialog.functions';

function generateNotificationsObjectNotify(t, notificationStr) {
  const notificationType = getNotificationType(t);
  const notificationChannel = getNotificationChannel(t);

  let notifications = {};

  // Initialize notification object
  for (let nType of notificationType) {
    notifications[nType.id] = {};

    for (let nChannel of notificationChannel) {
      notifications[nType.id][nChannel.id] = false;
    }
  }

  // Fill notification object
  if (notificationStr?.length > 0) {
    let parsedNotification = [];

    try {
      parsedNotification = JSON.parse(notificationStr);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          data: notificationStr,
        },
      });
    }

    for (let pN of parsedNotification) {
      if (notifications[pN.order_notification_type]) {
        notifications[pN.order_notification_type][
          pN.order_notification_channel
        ] = true;
      }
    }
  }

  return notifications;
}

export function generateNotificationsCustomersObject(t, data) {
  let notifications = generateNotificationFields(t);
  if (!data?.notification || !data?.subscriptions) {
    return notifications;
  }

  for (let n of data.notification) {
    if (!n.event || !n.actor || !n.channel) {
      continue;
    }

    const id = `${n.event}-${n.actor}-${n.channel}`;
    let newObj = {};

    if (parseInt(n.channel) === 1) {
      newObj.title = n.title;
      newObj.message = n.message;
    } else if (parseInt(n.channel) === 2) {
      newObj.message = n.message;
    }

    const checked = data.subscriptions.find(
      (s) => id === `${s.event}-${s.actor}-${s.channel}`
    );

    notifications[id] = {
      isChecked: Boolean(checked),
      ...newObj,
    };
  }
  return notifications;
}

export function getInitialValuesNotificationsCustomers(
  t,
  defaultNotificationsData,
  defaultNotificationsSubscriptionsData
) {
  const notify = {
    notification: defaultNotificationsData,
    subscriptions: defaultNotificationsSubscriptionsData,
  };

  const initialValues = generateNotificationsCustomersObject(t, notify);
  return initialValues;
}

export function getInitialValues(t, settings) {
  let initialValues = {
    'Adresnica-KontrolaSeriskiBroj': false,
    'Adresnica-PrikaziNovaFormaPosleKreiranje': false,
    'Adresnica-DefaultFreeInputForTheReceiver': false,
    'Company-Address': '',
    'Company-Telephone': '',
    'Company-CEO': '',
    'Company-Name': '',
    'Company-Email': '',
    'Company-VatNumber': '',
    'Company-PersonalNumber': '',
    'Company-BankAccount': '',
    'Company-EmailComplaints': '',
    'Company-Phone': '',
    'Company-Phone2': '',
    'Config-NpoKlientId': 0,
    'Config-FakturaKlientId': 0,
    'Config-NvpKlientId': 0,
    'Config-NppKlientId': 0,
    'Config-PovratenDokumentKlientId': 0,
    'Customer-DefaultPricelistId': 1,
    'Customer-RequiredEmail': false,
    'Customer-RequiredMobileOrPhone': false,
    'Customer-RequiredBankAccount': false,
    'Customer-RequiredEntityNumber': false,
    'Customer-RequiredTaxNumber': false,
    'Customer-RequiredHouseNumber': false,
    'Mail-Notifications': '',
    'Mail-RegistrationAdminApprove': '',
    'Notification-Subscriptions': '[]',
    'SMS-DefaultGateway': '',
    'SMS-NotificationsNumber': '',
    'Web-SaveImportListContent': false,
    'Input-Validation': '[]',
  };

  for (let s in settings?.data) {
    const item = settings.data[s];
    initialValues[`${item.section}-${item.ident}`] = item.value;
  }

  initialValues['Notification-Subscriptions'] =
    generateNotificationsObjectNotify(
      t,
      initialValues['Notification-Subscriptions']
    );

  return initialValues;
}

export function toApiDataNotificationsCustomers(values, section) {
  let notification = [];

  for (let notificationObj in values) {
    if (!values[notificationObj].isChecked) {
      continue;
    }

    const [nType, nrType, nChannel] = notificationObj.split('-');

    if (section === 'subscriptions') {
      notification.push({
        event: nType,
        actor: nrType,
        channel: nChannel,
      });
    } else {
      notification.push({
        event: nType,
        actor: nrType,
        channel: nChannel,
        ...(parseInt(nChannel) === 1
          ? {
              title: values[notificationObj].title,
              message: values[notificationObj].message,
            }
          : {
              message: values[notificationObj].message,
            }),
      });
    }
  }

  return notification;
}

export function toApiData(initialData, values, section) {
  let apiData = {};

  for (const obj of initialData.data) {
    const id = initialData.data.find(
      (o) => o.section === section && o.ident === obj.ident
    )?.id;

    // Something unexpected happened
    if (!id) {
      continue;
    }

    if (obj.section === section) {
      if (obj.section === 'Notification' && obj.ident === 'Subscriptions') {
        let notification = [];

        for (let nType in values[`${section}-${obj.ident}`]) {
          for (let nChannel in values[`${section}-${obj.ident}`][nType]) {
            if (values[`${section}-${obj.ident}`][nType][nChannel]) {
              notification.push({
                event: nType,
                channel: nChannel,
              });
            }
          }
        }

        try {
          apiData[id] = JSON.stringify(notification);
        } catch (e) {
          Sentry.captureException(e, {
            extra: {
              data: notification,
            },
          });
        }
      } else {
        apiData[id] = values[`${section}-${obj.ident}`];
      }
    }
  }

  return apiData;
}
