import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import { usePhoneOptionsContext } from '../../../../../context/PhoneOptionsContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import { WithData } from '../../../../../types/api';
import {
  CouriersDevices,
  SmartPOSTerminals,
} from '../../../../../types/api/smartPosTerminals';
import { debounceTimeout } from '../../../../../utils/constants/misc';
import { invalidPhoneNumberCharactersRegex } from '../../../../../utils/constants/phoneNumbers';
import { queryString } from '../../../../../utils/helpers/http';
import { getChangedValues } from '../../../../../utils/helpers/object';
import {
  formatMobileNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../../utils/helpers/phoneNumbers';
import AutoCompleteInput from '../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../Forms/ReactHookForm/FieldWithErrors';
import {
  FormFields,
  getDefaultValues,
  getValidationSchema,
  smartPOSTerminalsStatuses,
} from './AddEditDialog.functions';

type Props = {
  isEditDialog: boolean;
  data: SmartPOSTerminals | undefined;
  handleCreation: (data: FormFields) => void;
  handleEditing: (data: any) => void;
};

function FormContainer({
  isEditDialog,
  data,
  handleCreation,
  handleEditing,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const options = useMemo(() => smartPOSTerminalsStatuses(t), [t]);

  const defaultValues = useMemo(
    () => getDefaultValues(isEditDialog, data),
    [isEditDialog, data]
  );

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const methods = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { handleSubmit, reset, getValues, setValue } = methods;

  const courierName = useWatch<FormFields, 'courierName'>({
    control: methods.control,
    name: 'courierName',
  });

  const debouncedCourierFilter = useDebounce(courierName, debounceTimeout);

  const courierId = useWatch({ control: methods.control, name: 'courierId' });

  const { countryOptionTemplate, languages, selectedCountryTemplate } =
    usePhoneOptionsContext();

  const { data: devicesCourier } = useAxiosHook<WithData<CouriersDevices[]>>({
    url:
      '/couriers-devices' +
      queryString({
        employeeName: debouncedCourierFilter,
        page: 1,
        limit: 40,
      }),
  });

  const couriersDeviceOptions = useMemo(
    () =>
      devicesCourier?.data.map((item) => ({
        label: item.employeeName,
        value: item.employeeId,
      })) ?? [],
    [devicesCourier]
  );

  const devicesByCourierOptions = useMemo(
    () =>
      courierId
        ? devicesCourier?.data
            .filter((courier) => courier.employeeId === courierId)[0]
            .devices.map((item: any) => ({
              label: item.device_name_id,
              value: item.id,
            }))
        : [],
    [courierId, devicesCourier]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function handleFormSubmission(values: FormFields) {
    if (isEditDialog) {
      handleEditing(getChangedValues(values, defaultValues));
      return;
    }

    handleCreation(values);
  }

  return (
    <FormProvider {...methods}>
      <form
        id="smart-pos-terminals-create-edit-form"
        onSubmit={handleSubmit(handleFormSubmission)}
      >
        <div className="p-fluid">
          <FieldWithErrors name="username" label={t('Name')}>
            <Controller
              name="username"
              render={({ field }) => (
                <InputText
                  id="username"
                  name="username"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="courierId" label={t('Courier')}>
            <Controller
              name="courierId"
              render={({ field }) => (
                <AutoCompleteInput
                  // disabled={isLoadingDevicesCourier}
                  placeholder={t('Search')}
                  options={couriersDeviceOptions}
                  value={field.value}
                  filterValue={courierName}
                  onFilterChange={(e) => setValue('courierName', e)}
                  onSelectionChange={field.onChange}
                  filterDataCy="courierId"
                  optionsClassName="data-cy-courierId-options"
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="device_id" label={t('Device ID')}>
            <Controller
              name="device_id"
              render={({ field }) => (
                <Dropdown
                  id="device_id"
                  name="device_id"
                  options={devicesByCourierOptions}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={!Boolean(devicesByCourierOptions?.length)}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="terminal_id" label={t('Terminal ID')}>
            <Controller
              name="terminal_id"
              render={({ field }) => (
                <InputText
                  id="terminal_id"
                  name="terminal_id"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="activation_code" label={t('Activation Code')}>
            <Controller
              name="activation_code"
              render={({ field }) => (
                <InputText
                  id="activation_code"
                  name="activation_code"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="pin" label={t('Pin')}>
            <Controller
              name="pin"
              render={({ field }) => (
                <InputText
                  id="pin"
                  name="pin"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="mobile_number" label={t('Mobile')}>
            <div className="p-inputgroup">
              <Controller
                name="mobile_number_region"
                render={({ field }) => (
                  <Dropdown
                    style={{ padding: 0, width: '5rem' }}
                    id="mobile_number_region"
                    options={languages}
                    itemTemplate={countryOptionTemplate}
                    valueTemplate={selectedCountryTemplate}
                    data-cy="mobile_number_region"
                    {...field}
                  />
                )}
              />
              <Controller
                name="mobile_number"
                render={({ field }) => (
                  <InputText
                    name="mobile_number"
                    style={{ width: '100%' }}
                    value={formatMobileNumber(
                      field.value,
                      getValues()?.mobile_number_region ||
                        getPhoneorMobileNumberRegion(field.value)
                    )}
                    onChange={(e) => {
                      field.onChange(
                        e.target.value?.replace(
                          invalidPhoneNumberCharactersRegex,
                          ''
                        )
                      );
                    }}
                    className="data-cy-mobile"
                  />
                )}
              />
            </div>
          </FieldWithErrors>

          <FieldWithErrors name="status_id" label={t('Status')}>
            <Controller
              name="status_id"
              render={({ field }) => (
                <Dropdown
                  id="status_id"
                  name="status_id"
                  options={options}
                  value={String(field.value)}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>
        </div>
      </form>
    </FormProvider>
  );
}

export default FormContainer;
