import { faTags } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import usePageTitleToggler from '../../../hooks/usePageTitleToggler';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { WithPaginationAndSummary } from '../../../types/api';
import { Tariff, TariffCollection } from '../../../types/api/tariffs';
import { EntityIdRouteParams } from '../../../types/routing';
import {
  addTariffsResourceGuard,
  deleteTariffsResourceGuard,
  editTariffsResourceGuard,
} from '../../../utils/constants/auth/_api/tariffs';
import { viewGuard } from '../../../utils/constants/auth/smartPosTerminals';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import AddEditDialog from './Dialogs/AddEdit/AddEditDialog';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import ViewTariffDetailsDialog from './Dialogs/View/ViewTariffDetailsDialog';
import {
  Row,
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './Tariffs.functions';
import useTableFilters from './useTableFilters';

function Tariffs(): JSX.Element {
  const { id } = useParams<EntityIdRouteParams>();
  const { t } = useTranslation();
  const pageTitle = t('Tariffs');
  usePageTitle(pageTitle);
  const [action, setAction] = useState<string>('');
  const [caller, setCaller] =
    useState<'group-actions' | 'context-menu'>('context-menu');
  const addEpGuard = useEndpointGuard(addTariffsResourceGuard);
  const editEpGuard = useEndpointGuard(editTariffsResourceGuard);
  const deleteEpGuard = useEndpointGuard(deleteTariffsResourceGuard);
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Row>(tableStorageKey);
  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { data, isLoading, reload, error } = useAxiosHook<
    WithPaginationAndSummary<TariffCollection[], { redemption: string }>
  >({
    url: '/tariffs' + queryString(httpQueryObject(httpFiltersObj)),
  });

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'prices',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          setCaller,
          editEpGuard,
          deleteEpGuard
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          setCaller,
          editEpGuard,
          deleteEpGuard
        )}
      />,
    ],
    [
      columns,
      t,
      setContextMenuSelection,
      setAction,
      setCaller,
      editEpGuard,
      deleteEpGuard,
    ]
  );

  const {
    show: showViewDialog,
    hide: hideViewDialog,
    isVisible: isViewDialogVisible,
  } = useRouteDialog(
    RoutePaths.Tariffs,
    constructIdRoute(RoutePaths.ViewTariff, id ?? contextMenuSelection?.id)
  );

  const {
    show: showAddDialog,
    hide: hideAddDialog,
    isVisible: isAddDialogVisible,
  } = useRouteDialog(RoutePaths.Tariffs, RoutePaths.AddTariff, addEpGuard);

  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    isVisible: isDeleteDialogVisible,
  } = useRouteDialog(
    RoutePaths.Tariffs,
    constructIdRoute(RoutePaths.DeleteTariff, id ?? contextMenuSelection?.id),
    deleteEpGuard
  );

  const { data: tariffData, isLoading: isTariffDataLoading } =
    useAxiosHook<Tariff>(
      {
        url: `/tariffs/${id ?? contextMenuSelection?.id}`,
      },
      {
        skipWhen:
          (!id && !contextMenuSelection?.id) ||
          (!isAddDialogVisible &&
            // !isEditDialogVisible &&
            !isViewDialogVisible) ||
          !viewGuard,
      }
    );

  usePageTitleToggler(
    isTariffDataLoading ? t('Loading...') : t('Preview tariff'),
    t('Tariffs'),
    isViewDialogVisible
  );

  usePageTitleToggler(
    isTariffDataLoading
      ? t('Loading...')
      : t('Delete tariff {{serialNo}}', {
          serialNo: tariffData?.name ?? '',
        }),
    t('Tariffs'),
    isDeleteDialogVisible
  );

  useEffect(() => {
    if (action && contextMenuSelection) {
      setCaller('context-menu');

      if (action === 'view-details') {
        showViewDialog();
      }
      if (action === 'edit') {
        showAddDialog();
      }
      if (action === 'delete-order') {
        showDeleteDialog();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    setAction,
    showViewDialog,
    showAddDialog,
    showDeleteDialog,
  ]);

  function handleFromViewToEdit() {
    showAddDialog();
  }

  return (
    <div className="page prices">
      <HeaderPages
        title={pageTitle}
        subtitle={t('View and manage tariffs')}
        icon={faTags}
      >
        <Button
          type="button"
          label={t('Add a tariff')}
          icon="fas fa-plus"
          onClick={showAddDialog}
          className="main-btn"
        />
      </HeaderPages>

      <ViewTariffDetailsDialog
        visible={isViewDialogVisible}
        data={tariffData}
        isLoading={isTariffDataLoading}
        onHide={hideViewDialog}
        {...(editEpGuard ? { onEdit: handleFromViewToEdit } : {})}
      />

      <AddEditDialog
        isEditDialog={!!contextMenuSelection?.id}
        isShown={isAddDialogVisible}
        onHide={hideAddDialog}
        reloadTariffs={reload}
      />

      <DeleteDialog
        visible={isDeleteDialogVisible}
        data={contextMenuSelection!}
        onHide={hideDeleteDialog}
        reloadTariffs={reload}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={180}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={180}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            exportToExcelButton
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default Tariffs;
