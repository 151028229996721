import {
  faCircle,
  faLock,
  faPaperPlane,
  faRunning,
  faTruck,
  faUser,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs, { Dayjs } from 'dayjs';
import { Location } from 'history';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { IntBool } from '../../../enums/booleans';
import {
  OrderLocationType,
  OrderStatus,
  ReplacementShipment,
  ReturnDocument,
} from '../../../enums/orders';
import { ClientLookupCollection } from '../../../types/api/clients';
import { Numeric } from '../../../types/general';
import { Unpacked } from '../../../types/util';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../utils/helpers/formatting';
import { tryEnumValue, tryInt } from '../../../utils/helpers/parse';
import { contextMenuModel } from '../../../utils/helpers/primereact';
import {
  getSearchQueryParam,
  tryMultiSelectParam,
} from '../../../utils/helpers/searchQuery';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';
import { OrderPackaging } from './Dialogs/CreateEdit/CreateEditDialog.functions';

export type ClientOption = Pick<Unpacked<ClientLookupCollection>, 'id' | 'ime'>;

export type Order = {
  id: number;
  klient_naracatel_id: number;
  klient_od_ime: string | null;
  klient_do_ime: string | null;
  adresa_od: string | null;
  adresa_do: string | null;
  otkup: Numeric | null;
  cena: Numeric | null;
  datum_kreiranje: string | null;
  datum_isporaka: string | null;
  datum_priem: string | null;
  created_at: string | null;
  modified_at: string | null;
  komentar: string | null;
  fragile: boolean;
  two_man_delivery: boolean;
  adresnica_service_packaging_id: OrderPackaging;
  seriski_broj: string;
  shipment_location_type_id: OrderLocationType;
  shipment_location_name: string;
  barkod: string;
  status_id: number;
  status_name: string;
  tezina: Numeric | null;
  volumen: Numeric | null;
  payments: string | null | Payment[];
  created_by: { id: number; name: string } | null;
  modified_by: { id: number; name: string } | null;
  reference1: string | null;
  reference2: string | null;
  group_serial_number: string | null;
};

export enum ServiceType {
  Letter = '1',
  Package = '2',
}

export enum ProductType {
  Package = '1',
  Letter = '2',
}

export type Payment = {
  klient_id: number;
  usluga_id: ServiceType;
  opis: string | null;
  gotovina: number | null;
  iznos: number | null;
  kolicina: number | null;
  created: string;
  usluga_ime: string;
  tarifa_id: number;
  cenovnik_id: number;
  ediznos: number;
  ddv: number;
};

export type SingleOrder = {
  id: number;
  group_serial_number: string | null;
  adresa_od: string | null;
  adresa_do: string | null;
  seriski_broj: string | null;
  barkod: string | null;
  status_name: string | null;
  status_id: number;
  vrednost: string | null;
  otkup: string | null;
  klient_od_ime: string | null;
  klient_do_ime: string | null;
  klient_od_id: number | null;
  klient_do_id: number | null;
  mesto_od_id: number | null;
  mesto_do_id: number | null;
  mesto_od_ime: string | null;
  mesto_do_ime: string | null;
  telefon_od: string | null;
  telefon_do: string | null;
  mobilen_od: string | null;
  mobilen_do: string | null;
  broj_od: string | null;
  broj_do: string | null;
  vlez_od: string | null;
  vlez_do: string | null;
  stan_od: string | null;
  stan_do: string | null;
  povraten_dokument: ReturnDocument;
  return_document_type_id?: number | null;
  replacement_shipment: ReplacementShipment;
  datum_kreiranje: string | null;
  datum_priem: string | null;
  datum_isporaka: string | null;
  postar_od_name: string | null;
  postar_do_name: string | null;
  type_of_delivery: string | null;
  komentar: string | null;
  fragile: IntBool;
  two_man_delivery: IntBool;
  adresnica_service_packaging_id: OrderPackaging;
  proizvod_id: ProductType;
  proizvod_ime: string | null;
  personal_delivery: IntBool | null;
  specijaliziran_proizvod_id: number | null;
  visina: string | null;
  tezina: string | null;
  dolzina: string | null;
  shirina: string | null;
  volumen: string | null;
  cena: string | null;
  payments: Payment[] | null;
  delivery_term_id: number | null;
  klient_naracatel_id: number | null;
  ulica_od_id: number | null;
  street_from_name: string;
  ulica_do_id: number | null;
  street_to_name: string;
  reference1: string | null;
  reference2: string | null;
  reference_id: number | null;
  terminsko_vreme_od: string | null;
  terminsko_vreme_do: string | null;
  terminski_datum: string | null;
  hub_od_id: number | null;
  hub_do_id: number | null;
  return_shipment_id: number | null;
  origin_return_shipment_id: number | null;
  return_document_id: number | null;
  origin_return_document_id: number | null;
  klient_otkup_id: number | null;
  kolicina: string | null;
};

export type ListType = {
  id: Numeric;
  name: string;
};

export type Status = {
  id: Numeric;
  name: string;
  total: Numeric;
};

export const tableStorageKey = 'orders_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    seriski_broj: t('Serial Number'),
    datum_kreiranje: t('Created Date'),
    datum_priem: t('Received Date'),
    datum_isporaka: t('Shipping Date'),
    status_name: t('Status'),
    klient_do_ime: t('Recipient Name'),
    adresa_do: t('Recipient Address'),
    klient_od_ime: t('Sender Name'),
    adresa_od: t('Sender Address'),
    location: t('Location'),
    tezina: t('Weight'),
    volumen: t('Volume'),
    otkup: t('Redemption'),
    cena: t('Price'),
    komentar: t('Comment'),
    reference1: t('Reference 1'),
    reference2: t('Reference 2'),
    group_serial_number: t('Group Serial Number'),
    created_by: t('Created By'),
    created_at: t('Created at'),
    modified_by: t('Modified by'),
    modified_at: t('Modified at'),
  };
}

function getSemaphoreIcon(t: TFunction, timeDiff: number) {
  let color;
  let tooltip;

  if (isNaN(timeDiff)) {
    color = 'var(--bluegray-600)';
    tooltip = t('Unknown');
  } else if (timeDiff < 24) {
    color = 'var(--green-500)';
    tooltip = t('Less than 24 hours');
  } else if (timeDiff >= 24 && timeDiff < 72) {
    color = 'var(--yellow-500)';
    tooltip = t('Between 24 hours and 72 hours');
  } else {
    color = 'var(--red-500)';
    tooltip = t('More than 72 hours');
  }

  return (
    <span data-tip={tooltip} data-for="tooltip-right">
      <FontAwesomeIcon
        icon={faCircle}
        className="semaphore"
        style={{ color }}
      />
    </span>
  );
}

export function additionalColumnProperties(
  t: TFunction,
  column:
    | keyof ReturnType<typeof getColumnHeadersMap>
    | 'time_from_pickup'
    | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>,
  readGuard: boolean,
  editGuard: boolean,
  statusTrackingGuard: boolean,
  historyGuard: boolean,
  deleteGuard: boolean,
  cancelGuard: boolean
) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'time_from_pickup':
      return {
        body: (row: Order & { time_from_pickup: number }) => {
          return getSemaphoreIcon(t, row[column]);
        },

        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
          textAlign: 'center',
        },
      };

    case 'seriski_broj':
    case 'status_name':
    case 'adresa_do':
    case 'adresa_od':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'klient_do_ime':
    case 'klient_od_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'created_at':
    case 'modified_at':
    case 'datum_isporaka':
    case 'datum_priem':
    case 'datum_kreiranje':
      return {
        body: (row: Order) => dateCell(row[column] ?? ''),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'tezina':
    case 'volumen':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'location':
      return {
        body: (row: Order) => {
          switch (row.shipment_location_type_id) {
            case OrderLocationType.PickupCourier:
            case OrderLocationType.DeliveryCourier:
              return (
                <div>
                  <FontAwesomeIcon
                    icon={faRunning}
                    style={{ marginRight: 8 }}
                  />
                  <span>{row.shipment_location_name}</span>
                </div>
              );
            case OrderLocationType.Relocation:
              return (
                <div>
                  <FontAwesomeIcon icon={faTruck} style={{ marginRight: 8 }} />
                  <span>{row.shipment_location_name}</span>
                </div>
              );
            case OrderLocationType.Sender:
              return (
                <div>
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{ marginRight: 8 }}
                  />
                  <span>{row.shipment_location_name}</span>
                </div>
              );
            case OrderLocationType.Recipient:
              return (
                <div>
                  <FontAwesomeIcon icon={faUser} style={{ marginRight: 8 }} />
                  <span>{row.shipment_location_name}</span>
                </div>
              );
            case OrderLocationType.PickupLocker:
            case OrderLocationType.DeliveryLocker:
              return (
                <div>
                  <FontAwesomeIcon icon={faLock} style={{ marginRight: 8 }} />
                  <span>{row.shipment_location_name}</span>
                </div>
              );
            case OrderLocationType.Hub:
              return (
                <div>
                  <FontAwesomeIcon
                    icon={faWarehouse}
                    style={{ marginRight: 8 }}
                  />
                  <span>{row.shipment_location_name}</span>
                </div>
              );
            default:
              return emptyCell;
          }
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'created_by':
    case 'modified_by':
      return {
        body: (row: Order) => row[column]?.name,
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'cena':
    case 'otkup':
      return {
        body: (row: Order) => {
          return typeof row[column] === 'string' ||
            typeof row[column] === 'number'
            ? currencyFormat(row[column] as string, { showCurrency: true })
            : emptyCell;
        },
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'komentar':
    case 'reference1':
    case 'reference2':
    case 'group_serial_number':
      return {
        body: (row: Order) => (!!row[column]?.trim() ? row[column] : emptyCell),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };
    case 'actions':
      return {
        body: (row: Order) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View Details'),
                  icon: 'fas fa-search',
                  disabled: !readGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-details');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Edit'),
                  icon: 'fas fa-edit',
                  disabled:
                    !editGuard || row.status_id >= OrderStatus.Cancelled,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('edit');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Status Tracking'),
                  icon: 'fas fa-search-location',
                  disabled: !statusTrackingGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('status-tracking');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('History'),
                  icon: 'fas fa-history',
                  disabled: !historyGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('history');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Print address document'),
                  icon: 'fas fa-address-book',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-address');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Print sticker'),
                  icon: 'fas fa-ticket-alt',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('print-sticker');
                  },
                },
                {
                  label: t('Delete order'),
                  icon: 'fas fa-trash',
                  disabled:
                    !deleteGuard || row.status_id >= OrderStatus.Cancelled,
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('delete-order');
                  },
                },
                {
                  label: t('Cancel order'),
                  icon: 'fas fa-times',
                  disabled:
                    !cancelGuard || row.status_id >= OrderStatus.Cancelled,
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('cancel-order');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {};
  }
}

export function generateGroupActions(
  t: TFunction,
  handlePrintAddressGroup: () => void,
  handlePrintStickerGroup: () => void,
  handleCMDCancelClick: () => void,
  handleCMDeleteClick: () => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>
) {
  const printAddressBook = {
    label: t('Print address book'),
    icon: 'fas fa-address-book',
    disabled: false,
    command: handlePrintAddressGroup,
  };
  const printSticker = {
    label: t('Print stickers'),
    icon: 'fas fa-ticket-alt',
    disabled: false,
    command: handlePrintStickerGroup,
  };
  const cancelOrders = {
    label: t('Cancel orders'),
    icon: 'fas fa-times',
    disabled: false,
    command: () => {
      setCaller('group-actions');
      handleCMDCancelClick();
    },
  };
  const deleteOrders = {
    label: t('Delete orders'),
    icon: 'fas fa-trash',
    disabled: false,
    command: () => {
      setCaller('group-actions');
      handleCMDeleteClick();
    },
  };

  return contextMenuModel([
    [
      // for now all true
      [true, printAddressBook],
      [true, printSticker],
      [true, cancelOrders],
      [true, deleteOrders],
    ],
  ]);
}

export function hoursToNow(
  datum_priem: Numeric | undefined | Date | Dayjs
): number {
  const dateReceivedAt = dayjs(datum_priem);

  if (!dateReceivedAt.isValid()) {
    return NaN;
  }

  return dayjs().diff(dateReceivedAt, 'hours');
}

export function getStatuses<T>(location: Location<T>) {
  const value = getSearchQueryParam(location.search, 'status') ?? null;

  return value
    ? tryMultiSelectParam(value)
        ?.filter((value) => tryEnumValue(tryInt(value), OrderStatus))
        ?.map(Number) ?? null
    : null;
}

export const semaphoreStatusesValues = [
  35, 40, 41, 45, 46, 47, 48, 50, 51, 55, 60, 65, 75,
];
