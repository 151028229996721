import './DeleteHubDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../context/ToastContext';
import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../../hooks/usePrevious';
import {
  errorToast,
  successToast,
} from '../../../../../../utils/helpers/primereact';
import Content from './Content';

type Props = {
  visible: boolean;
  onHide: () => void;
  selection: any;
  reloadHubLists: () => void;
};

function DeleteHubDialog({
  visible,
  onHide,
  selection,
  reloadHubLists,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { toastRef } = useContext(ToastContext);

  const { data, error, reload, isLoading } = useAxiosHook(
    {
      url: `/hubs/${selection?.id}`,
      method: 'DELETE',
    },
    {
      skipWhen: true,
    }
  );

  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  useEffect(() => {
    if (!data || data === prevData || !selection) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      t('Imported list {{selectedListName}} has been successfully deleted', {
        selectedListName: selection.name,
      })
    );

    reloadHubLists();
    onHide();
  }, [onHide, prevData, reloadHubLists, selection, data, t, toastRef]);

  useEffect(() => {
    if (!error || error === prevError || !selection) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t(
        'An error occured while trying to delete the import list {{selectedListName}}.',
        { selectedListName: selection.name }
      )
    );
  }, [onHide, prevError, selection, error, t, toastRef]);

  const isHubSetAsANativeHubForSomeEmployees = error?.response?.status === 412;

  const header = !isHubSetAsANativeHubForSomeEmployees
    ? t('Delete {{name}}', { name: selection?.name ?? '' })
    : t('We had trouble deleting {{name}}.', { name: selection?.name ?? '' });

  const footer = !error ? (
    <>
      <Button
        type="button"
        label={t('Cancel')}
        onClick={onHide}
        className="p-button-text"
      />

      <Button
        type="button"
        label={t('Delete')}
        onClick={() => reload()}
        className="p-button-danger"
        data-cy="submit-btn"
      />
    </>
  ) : (
    <Button label={t('Close')} onClick={onHide} />
  );

  return (
    <Dialog
      visible={visible}
      header={header}
      footer={footer}
      onHide={onHide}
      resizable={false}
    >
      <Content
        data={data}
        error={error}
        isLoading={isLoading}
        isHubSetAsANativeHubForSomeEmployees={
          isHubSetAsANativeHubForSomeEmployees
        }
        selection={selection}
        successCallback={() => {}}
        errorCallback={onHide}
      />
    </Dialog>
  );
}

export default DeleteHubDialog;
