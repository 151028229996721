import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import { SmartPOSTerminals } from '../../../../../types/api/smartPosTerminals';
import { Numeric } from '../../../../../types/general';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import { generateErrorMsg } from '../../../Orders/DeletedOrders/DeletedOrders.functions';

type Props = {
  visible: boolean;
  data: SmartPOSTerminals[];
  onHide: () => void;
  reloadTerminals: () => void;
  setSelectionMultiple: Dispatch<SetStateAction<any[]>>;
};

function DeleteDialog({
  visible,
  data,
  onHide,
  reloadTerminals,
  setSelectionMultiple,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { toastRef } = useContext(ToastContext);
  const {
    data: deleteData,
    error: deleteError,
    reload: deleteReload,
    isLoading: isDeleteLoading,
  } = useAxiosHook<undefined, { error?: string }>();

  function handleDeleteTerminal(deviceId: Numeric) {
    if (!deviceId) {
      return;
    }
    deleteReload({ url: `smart-pos-terminals/${deviceId}`, method: 'DELETE' });
  }

  const prevDeleteError = usePrevious(deleteError);
  const prevDeleteData = usePrevious(deleteData);

  useEffect(() => {
    if (!deleteData || deleteData === prevDeleteData) {
      return;
    }

    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        data?.length > 1
          ? t('Terminals successfully deleted.')
          : t('Terminal {{id}} was successfully deleted.', {
              id: data[0]?.id ?? '',
            })
      );
    }
    onHide();
    reloadTerminals();
    setSelectionMultiple([]);
  }, [
    data,
    deleteData,
    prevDeleteData,
    onHide,
    reloadTerminals,
    setSelectionMultiple,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (!deleteError || deleteError === prevDeleteError) {
      return;
    }
    const errText = deleteError?.response?.data?.error;

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        data?.length > 1
          ? t('Failed group deleting. {{errorMessage}}', {
              errorMessage: errText ? generateErrorMsg(t, errText) : '',
            })
          : t('Deleting terminal {{serialNo}} failed. {{errorMessage}}.', {
              serialNo: data[0]?.id ?? '',
              errorMessage: errText ? generateErrorMsg(t, errText) : '',
            })
      );
    }

    onHide();
  }, [data, deleteError, onHide, prevDeleteError, t, toastRef]);

  function handleDeleteSubmission() {
    if (!data?.length) {
      return;
    }
    data.forEach((o) => handleDeleteTerminal(o.id));
  }

  const dialogFooter = (
    <div className='className="p-pt-4"'>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="button"
        label={isDeleteLoading ? t('Deleting...') : t('Delete')}
        disabled={isDeleteLoading || data.length === 0}
        onClick={() => {
          handleDeleteSubmission();
        }}
        className="p-button-danger"
        data-cy="submit-btn"
      />
    </div>
  );

  return (
    <Dialog
      header={data?.length > 1 ? t('Delete terminals') : t('Delete terminal')}
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      onHide={onHide}
      style={{ width: 480, maxWidth: '100%' }}
    >
      {data?.length > 0 && (
        <p>
          {data?.length > 1 ? (
            t('Are you sure you want to delete this group of terminals?')
          ) : (
            <>
              {t('Are you sure you want to delete the following terminal?')}{' '}
              <b>{data[0]?.username}</b>
            </>
          )}
          <br />
          <br />
          {t('This action cannot be undone.')}
        </p>
      )}
    </Dialog>
  );
}

export default DeleteDialog;
