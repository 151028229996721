import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { getOrderStatusConfig } from '../../../../../configs/orders';
import { CourierDeliveryAssignmentCollection } from '../../../../../types/api/orders';
import { ContextMenuItem } from '../../../../../types/primereact';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../../../types/util';
import {
  emptyCell,
  noColumnStyle,
} from '../../../../../utils/constants/tables';
import { dateCell, weightCell } from '../../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../../utils/helpers/formatting';
import { contextMenuModel } from '../../../../../utils/helpers/primereact';

export type Row = Unpacked<CourierDeliveryAssignmentCollection>;

type Column = TableColumns<
  Pick<
    Row,
    | 'serial_number'
    | 'status_id'
    | 'order_reception_date'
    | 'order_delivery_date'
    | 'sender_name'
    | 'recipient_name'
    | 'recipient_place_name'
    | 'weight'
    | 'redemption'
  >
>;

export const tableStorageKey = 'warehouse_transferShipmentAssignment_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    serial_number: t('Serial No.'),
    status_id: t('Status'),
    order_reception_date: t('Received At'),
    order_delivery_date: t('Delivered At'),
    sender_name: t('Sender'),
    recipient_name: t('Recipient'),
    recipient_place_name: t('Delivery Location'),
    weight: t('Weight'),
    redemption: t('Redemption'),
  };
}

export function additionalColumnProperties(t: TFunction) {
  const orderStatusConfig = getOrderStatusConfig(t);

  return (column: Column): ColumnProps => {
    switch (column) {
      case 'no':
        return noColumnStyle;

      case 'serial_number':
        return {
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'status_id':
        return {
          body: (row: Row) => orderStatusConfig[row[column]]?.label,
          style: { width: 300 },
          bodyStyle: { width: 300 },
        };

      case 'order_reception_date':
        return {
          body: (row: Row) => dateCell(row[column]),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'order_delivery_date':
        return {
          body: (row: Row) =>
            row[column] !== null ? dateCell(row[column]) : emptyCell,
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'sender_name':
      case 'recipient_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'recipient_place_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'weight':
        return {
          body: (row: Row) => weightCell(t, row[column]),
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'redemption':
        return {
          body: (row: Row) =>
            currencyFormat(row[column], { showCurrency: true }),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };
    }
  };
}

export function generateContextMenu({
  t,
  onViewClick,
  isAssignShown,
  onAssignClick,
  isCancelShown,
  onCancelClick,
}: {
  t: TFunction;
  onViewClick: () => void;
  isAssignShown: boolean;
  onAssignClick: () => void;
  isCancelShown: boolean;
  onCancelClick: () => void;
}): ContextMenuItem[] {
  const viewOption: ContextMenuItem = {
    label: t('View Details'),
    icon: 'fas fa-search',
    command: onViewClick,
  };

  const assignOption: ContextMenuItem = {
    label: t('Assign'),
    icon: 'fas fa-barcode',
    command: onAssignClick,
  };

  const cancelOption = {
    label: t('Cancel assignment'),
    icon: 'fas fa-ban',
    command: onCancelClick,
  };

  return contextMenuModel([
    [[true, viewOption]],
    [
      [isAssignShown, assignOption],
      [isCancelShown, cancelOption],
    ],
  ]);
}
