import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getNotificationChannel,
  getNotificationType,
} from '../../../../utils/constants/entities/notifications';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function Notifications() {
  const { t } = useTranslation();

  const notificationType = useMemo(() => getNotificationType(t), [t]);
  const notificationChannel = useMemo(() => getNotificationChannel(t), [t]);

  const { values } = useFormikContext();
  console.log('values', values['Notification-Subscriptions']);

  return (
    <div className="notifications-page">
      <FieldWithErrors name="notification" label={false}>
        <div className="notification-head">
          <span>{t('Notification type')}</span>

          {notificationChannel.map((nChannel) => (
            <span key={nChannel.id} title={nChannel.description}>
              {nChannel.title}
            </span>
          ))}
        </div>

        {notificationType.map((nType) => (
          <div key={nType.id} className="notification">
            <span title={nType.description}>{nType.title}</span>

            <Field
              key={`Notification-Subscriptions.${nType.id}.1`}
              name={`Notification-Subscriptions.${nType.id}.1`}
              as={Checkbox}
              checked={values['Notification-Subscriptions'][nType.id][1]}
            />

            <Field
              key={`Notification-Subscriptions.${nType.id}.2`}
              name={`Notification-Subscriptions.${nType.id}.2`}
              as={Checkbox}
              checked={values['Notification-Subscriptions'][nType.id][2]}
            />
          </div>
        ))}
      </FieldWithErrors>
    </div>
  );
}

export default Notifications;
