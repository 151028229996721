import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UseAxiosReturn } from '../../../../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../../../../hooks/useEndpointGuard';
import useTableColumns from '../../../../../../../hooks/useTableColumns';
import { UseTableStateReturn } from '../../../../../../../hooks/useTableState';
import {
  CourierReceptionCollectedCollection,
  CourierSummaryCollection,
} from '../../../../../../../types/api/orders';
import {
  receiveShipmentGuard,
  undoReceivedShipmentGuard,
} from '../../../../../../../utils/constants/auth/courierShipmentReception';
import { sequential } from '../../../../../../../utils/helpers/functions';
import { tryInt } from '../../../../../../../utils/helpers/parse';
import Table from '../../../../../../DataTable/Table/Table';
import CommonStateContext from '../../../../_AssignmentAndReception/CommonStateContext';
import {
  Row,
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './NewShipments.functions';

type Props = {
  isDateValid: boolean;
  request: UseAxiosReturn<CourierReceptionCollectedCollection>;
  statsRequest: UseAxiosReturn<CourierSummaryCollection>;
  tableState: UseTableStateReturn<Row>;
  cancelCallback: (orderID: Row['order_id']) => void;
  showOrderDialog: () => void;
  receptionCallback: () => void;
  shouldDisplayData: boolean;
};

function NewShipments({
  isDateValid,
  request,
  statsRequest,
  tableState,
  cancelCallback,
  showOrderDialog,
  receptionCallback,
  shouldDisplayData,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const receiveGuard = useEndpointGuard(receiveShipmentGuard);
  const cancelGuard = useEndpointGuard(undoReceivedShipmentGuard);

  const { rowClassName } = useContext(CommonStateContext);

  const {
    page,
    limit,
    tableRef,
    sortField,
    sortOrder,
    selection,
    setPage,
    setLimit,
    setSortField,
    setSortOrder,
    setSelection,
  } = tableState;

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'warehouse_courierShipmentReceptionNewShimpents',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties(t)
    );

  const { data, error, isLoading, reload } = request;

  const contextMenuModel = useMemo(() => {
    function handleCancelCMClick() {
      if (!selection) {
        return;
      }

      cancelCallback(selection?.order_id);
    }

    const isReceiveShown =
      isDateValid && receiveGuard && !!tryInt(selection?.is_candidate);

    const isCancelShown =
      isDateValid &&
      cancelGuard &&
      !!selection?.is_candidate &&
      !parseInt(selection.is_candidate);

    return generateContextMenu({
      t,
      onViewClick: showOrderDialog,
      isReceiveShown,
      onReceiveClick: receptionCallback,
      isCancelShown,
      onCancelClick: handleCancelCMClick,
    });
  }, [
    cancelCallback,
    cancelGuard,
    isDateValid,
    receiveGuard,
    receptionCallback,
    selection,
    showOrderDialog,
    t,
  ]);

  return (
    <Table
      tableRef={tableRef}
      columns={columns}
      data={shouldDisplayData ? data : undefined}
      isLoading={isLoading}
      hasError={!!error}
      reload={() => sequential(reload, statsRequest.reload)}
      isReloadDisabled={!shouldDisplayData}
      setPage={setPage}
      setLimit={setLimit}
      sortField={sortField}
      rows={limit}
      filterHeight={380}
      setSortField={setSortField}
      setSortOrder={setSortOrder}
      setSelection={setSelection}
      sortOrder={sortOrder}
      selection={selection}
      storageString={tableStorageKey}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      columnOptions={columnOptions}
      headerTitle={t('New Shipments')}
      onRowDoubleClick={showOrderDialog}
      contextMenuModel={contextMenuModel}
      rowClassName={rowClassName}
    />
  );
}

export default NewShipments;
