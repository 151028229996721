import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useTranslation } from 'react-i18next';

import { usePhoneOptionsContext } from '../../../../../../context/PhoneOptionsContext';
import { isStreetFreeInputAllowed } from '../../../../../../utils/constants/misc';
import { invalidPhoneNumberCharactersRegex } from '../../../../../../utils/constants/phoneNumbers';
import {
  placeItemTemplate,
  placeValueTemplate,
} from '../../../../../../utils/helpers/misc';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../../../utils/helpers/phoneNumbers';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';

function GeneralInfoTab({
  clientData,
  clientTypeOptions,
  municipalitiesOptions,
  isMunicipalitiesLoading,
  places,
  placesOptions,
  isPlacesLoading,
  reloadPlaces,
  streets,
  streetsOptions,
  isStreetsLoading,
  reloadStreets,
  priceListOptions,
  arePriceListsLoading,
  occupationOptions,
  isOccupationsLoading,
  isEditDialog,
}) {
  const { t } = useTranslation();
  const { values, errors, touched, initialValues, setFieldValue } =
    useFormikContext();

  const { countryOptionTemplate, languages, selectedCountryTemplate } =
    usePhoneOptionsContext();
  const onChangeClientType = (e) => {
    const { name, value } = e.target;
    // set direkcija_id to client_id for showing default this client is headquarter
    // and for showing Charging Departments tab
    // set departments default for edit if there  Registering customer
    setFieldValue(name, value);
    setFieldValue(
      'direkcija_id',
      value
        ? isEditDialog
          ? clientData?.id
          : initialValues.direkcija_id
        : null
    );
    setFieldValue(
      'maticen_broj',
      value
        ? isEditDialog
          ? clientData?.maticen_broj
          : initialValues.maticen_broj
        : null
    );
    setFieldValue(
      'danocen_broj',
      value
        ? isEditDialog
          ? clientData?.danocen_broj
          : initialValues.danocen_broj
        : null
    );
    setFieldValue(
      'direkcija_ime',
      value
        ? isEditDialog
          ? clientData?.id
          : t('This client is a headquarter')
        : null
    );
    setFieldValue(
      'departments',
      value && isEditDialog && clientData?.departments?.length > 0
        ? clientData.departments
        : [
            {
              id: null,
              name: 'Default',
              headquarters_id: null,
              charge_customer_id: 0,
            },
          ]
    );
  };

  return (
    <div className="general-info-tab p-fluid">
      <div className="p-grid">
        <div className="p-col-12 p-md-6">
          <FieldWithErrors name="pravno_lice" label={t('Type')}>
            <Field
              as={Dropdown}
              name="pravno_lice"
              inputId="pravno_lice"
              options={clientTypeOptions}
              className="data-cy-client_type"
              onChange={onChangeClientType}
            />
          </FieldWithErrors>

          <FieldWithErrors name="ime" label={t('Name')}>
            <Field
              as={InputText}
              name="ime"
              id="ime"
              maxLength="210"
              data-cy="name"
            />
          </FieldWithErrors>

          <FieldWithErrors name="municipality" label={t('Municipality')}>
            <Field
              as={Dropdown}
              name="municipality"
              inputId="municipality"
              options={municipalitiesOptions}
              filter
              filterPlaceholder="Search"
              placeholder={isMunicipalitiesLoading ? t('Loading...') : null}
              onChange={(e) => {
                setFieldValue('municipality', e.value);
                setFieldValue('municipality_id', e.value);

                reloadPlaces({ url: `municipalities/${e.value}/places` });

                setFieldValue('mesto_id', '');
                setFieldValue('place', '');
                setFieldValue('street', '');
                setFieldValue('broj', '');
                setFieldValue('vlez', '');
                setFieldValue('stan', '');
              }}
              className="data-cy-municipality"
            />
          </FieldWithErrors>

          <FieldWithErrors
            name="place"
            label={t('Place')}
            includeErrorsFor={['mesto_id']}
          >
            <Field
              as={Dropdown}
              name="place"
              inputId="place"
              disabled={!values.municipality || isPlacesLoading}
              options={placesOptions}
              itemTemplate={placeItemTemplate}
              valueTemplate={placeValueTemplate}
              filter
              filterPlaceholder={t('Search')}
              placeholder={isPlacesLoading ? t('Loading...') : null}
              className={classNames('data-cy-place', {
                invalid: errors.place && touched.place,
              })}
              onChange={(e) => {
                if (e.value) {
                  const place = places.find((p) => p.ime === e.value);
                  reloadStreets({ url: `places/${place.id}/streets` });

                  setFieldValue('mesto_id', place.id);
                  setFieldValue('place', e.value);
                } else {
                  setFieldValue('mesto_id', '');
                  setFieldValue('place', '');
                  setFieldValue('street', '');
                  setFieldValue('broj', '');
                  setFieldValue('vlez', '');
                  setFieldValue('stan', '');
                }
              }}
            />
          </FieldWithErrors>

          <FieldWithErrors
            name="address"
            label={isStreetFreeInputAllowed ? t('Address') : t('Street')}
            includeErrorsFor={[
              'ulica_id',
              'adresa',
              'broj',
              'vlez',
              'stan',
              'street',
            ]}
          >
            {isStreetFreeInputAllowed ? (
              <Field
                as={InputText}
                name="adresa"
                id="adresa"
                disabled={!values.municipality || !values.place}
                maxLength="256"
              />
            ) : (
              <>
                <Field
                  as={Dropdown}
                  name="street"
                  inputId="street"
                  disabled={
                    !values.municipality || !values.place || isStreetsLoading
                  }
                  options={streetsOptions}
                  filter
                  filterPlaceholder={t('Search')}
                  placeholder={isStreetsLoading ? t('Loading...') : null}
                  className={classNames('data-cy-street', {
                    invalid: errors.place && touched.place,
                  })}
                  onChange={(e) => {
                    const street = streets.find((p) => p.ime === e.value);

                    setFieldValue('street', e.value);
                    setFieldValue('ulica_id', street.id);
                  }}
                />

                <div className="street-numbers">
                  <Field
                    as={InputText}
                    name="broj"
                    id="broj"
                    maxLength="10"
                    placeholder={t('Street No.')}
                    className={classNames({
                      invalid: errors.broj && touched.broj,
                    })}
                    disabled={!values.street}
                    data-cy="street_no"
                  />

                  <span className="divider">/</span>

                  <Field
                    as={InputText}
                    name="vlez"
                    id="vlez"
                    maxLength="10"
                    placeholder={t('Entrance No.')}
                    className={classNames({
                      invalid: errors.vlez && touched.vlez,
                    })}
                    disabled={!values.street}
                    data-cy="entrance_no"
                  />

                  <span className="divider">/</span>

                  <Field
                    as={InputText}
                    name="stan"
                    id="stan"
                    maxLength="10"
                    placeholder={t('Flat No.')}
                    className={classNames({
                      invalid: errors.stan && touched.stan,
                    })}
                    disabled={!values.street}
                    data-cy="flat_no"
                  />
                </div>
              </>
            )}
          </FieldWithErrors>
          <FieldWithErrors name="dejnost_id" label={t('Occupation')}>
            <Field
              as={Dropdown}
              name="dejnost_id"
              inputId="dejnost_id"
              filter
              options={occupationOptions}
              placeholder={isOccupationsLoading ? t('Loading...') : null}
              disabled={isOccupationsLoading}
              className="data-cy-occupation"
            />
          </FieldWithErrors>

          <FieldWithErrors name="referenca" label={t('Reference No.')}>
            <Field
              as={InputText}
              name="referenca"
              id="referenca"
              maxLength="45"
              data-cy="reference_no"
            />
          </FieldWithErrors>

          <FieldWithErrors name="komentar" label={t('Comment')}>
            <Field
              as={InputTextarea}
              name="komentar"
              id="komentar"
              autoResize
              maxLength="500"
              data-cy="comment"
            />
          </FieldWithErrors>

          <FieldWithErrors name="interen_klient" label={false}>
            <Field
              as={Checkbox}
              name="interen_klient"
              inputId="interen_klient"
              checked={!!values.interen_klient}
            />
            <label htmlFor="interen_klient">
              {t('This client is created for internal purposes')}
            </label>
          </FieldWithErrors>
        </div>

        <div className="p-col-12 p-md-6">
          <span className="dialog-section-divider">{t('Contact Info')}</span>
          <FieldWithErrors name="mobilen" label={t('Mobile')}>
            <div className="p-inputgroup number-group">
              <Field
                as={Dropdown}
                className="mobilen_region"
                style={{ padding: 0, width: '5rem' }}
                id="mobilen_region"
                name="mobilen_region"
                options={languages}
                itemTemplate={countryOptionTemplate}
                valueTemplate={selectedCountryTemplate}
                data-cy="mobilen_region"
              />

              <Field
                as={InputText}
                name="mobilen"
                id="mobilen"
                value={formatMobileNumber(
                  values.mobilen,
                  values.mobilen_region ||
                    getPhoneorMobileNumberRegion(values.mobilen)
                )}
                onChange={(e) => {
                  setFieldValue('mobilen', e.target.value);
                }}
                data-cy="mobile"
              />
            </div>
          </FieldWithErrors>

          <FieldWithErrors name="telefon" label={t('Phone')}>
            <div className="p-inputgroup number-group">
              <Field
                as={Dropdown}
                className="telefon_region"
                style={{ padding: 0, width: '5rem' }}
                id="telefon_region"
                name="telefon_region"
                options={languages}
                itemTemplate={countryOptionTemplate}
                valueTemplate={selectedCountryTemplate}
                data-cy="telefon_region"
              />

              <Field
                as={InputText}
                name="telefon"
                id="telefon"
                value={formatPhoneNumber(
                  values.telefon,
                  values.telefon_region ||
                    getPhoneorMobileNumberRegion(values.telefon)
                )}
                onChange={(e) => {
                  setFieldValue(
                    'telefon',
                    e.target.value?.replace(
                      invalidPhoneNumberCharactersRegex,
                      ''
                    )
                  );
                }}
                data-cy="phone"
              />
            </div>
          </FieldWithErrors>

          <FieldWithErrors name="faks" label={t('Fax')}>
            <div className="p-inputgroup number-group">
              <Field
                as={Dropdown}
                className="faks_region"
                style={{ padding: 0, width: '5rem' }}
                id="faks_region"
                name="faks_region"
                options={languages}
                itemTemplate={countryOptionTemplate}
                valueTemplate={selectedCountryTemplate}
                data-cy="faks_region"
              />

              <Field
                as={InputText}
                name="faks"
                id="faks"
                value={formatPhoneNumber(
                  values.faks,
                  values.faks_region ||
                    getPhoneorMobileNumberRegion(values.faks)
                )}
                onChange={(e) => {
                  setFieldValue(
                    'faks',
                    e.target.value?.replace(
                      invalidPhoneNumberCharactersRegex,
                      ''
                    )
                  );
                }}
                data-cy="fax"
              />
            </div>
          </FieldWithErrors>

          <FieldWithErrors name="web" label={t('Website')}>
            <Field
              as={InputText}
              name="web"
              id="web"
              maxLength="255"
              data-cy="web"
            />
          </FieldWithErrors>

          <FieldWithErrors name="email" label={t('Email (username)')}>
            <Field
              as={InputText}
              name="email"
              id="email"
              maxLength="254"
              data-cy="email"
            />
          </FieldWithErrors>

          <FieldWithErrors name="odgovorno_lice" label={t('Person in charge')}>
            <Field
              as={InputText}
              name="odgovorno_lice"
              id="odgovorno_lice"
              maxLength="45"
              data-cy="person_in_charge"
            />
          </FieldWithErrors>

          <FieldWithErrors name="kontakt_lice" label={t('Contact person')}>
            <Field
              as={InputText}
              name="kontakt_lice"
              id="kontakt_lice"
              maxLength="45"
              data-cy="contact_person"
            />
          </FieldWithErrors>
        </div>
      </div>
    </div>
  );
}

export default GeneralInfoTab;
