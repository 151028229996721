import {
  createSmartPosTerminalsResourceGuard,
  deleteSmartPosTerminalResourceGuard,
  editSmartPosTerminalResourceGuard,
  smartPosTerminalResourceGuard,
  smartPosTerminalsCollectionGuard,
} from './_api/smartPosTerminals';

export const createGuard = createSmartPosTerminalsResourceGuard;
export const editGuard = editSmartPosTerminalResourceGuard;
export const viewGuard = smartPosTerminalResourceGuard;
export const deleteGuard = [
  smartPosTerminalsCollectionGuard,
  deleteSmartPosTerminalResourceGuard,
];

export const routeGuard = [
  smartPosTerminalResourceGuard,
  smartPosTerminalsCollectionGuard,
];
