import classNames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { getProductTypeOptions } from '../../../configs/product';
import { getServiceTypeOptions } from '../../../configs/service';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [nameFilter, setNameFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'name') ?? ''
  );
  const [productTypeId, setProductTypeId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'roduct_id') ?? null
  );
  const [serviceTypeId, setServiceTypeId] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'service_id') ?? null
  );

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);

  const filtersArr = useMemo(
    () => [debouncedNameFilter, productTypeId, serviceTypeId],
    [debouncedNameFilter, productTypeId, serviceTypeId]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const productTypeOptions = useMemo(() => getProductTypeOptions(t), [t]);
  const serviceTypeOptions = useMemo(() => getServiceTypeOptions(t), [t]);

  useEffect(() => {
    setPage(1);
  }, [setPage, debouncedNameFilter, productTypeId, serviceTypeId]);

  useSearchQueryParam('name', debouncedNameFilter);
  useSearchQueryDropdownParam(
    'product_id',
    productTypeId,
    setProductTypeId,
    productTypeOptions
  );
  useSearchQueryDropdownParam(
    'service_id',
    serviceTypeId,
    setServiceTypeId,
    serviceTypeOptions
  );

  const httpFiltersObj = useMemo(
    () => ({
      name: debouncedNameFilter,
      product_id: productTypeId,
      service_id: serviceTypeId,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      debouncedNameFilter,
      haveFiltersChanged,
      limit,
      page,
      productTypeId,
      serviceTypeId,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    nameFilter,
    productTypeId,
    serviceTypeId,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_name">{t('Name')}</label>
            <InputText
              id="filter_name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              className={classNames({
                sidebar_filter_active: nameFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_product">{t('Product')}</label>
            <Dropdown
              id="filter_product"
              value={productTypeId}
              options={productTypeOptions}
              onChange={(e) => setProductTypeId(e.value ?? null)}
              showClear
              className={classNames({
                sidebar_filter_active: productTypeId,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_service">{t('Service')}</label>
            <Dropdown
              id="filter_service"
              value={serviceTypeId}
              options={serviceTypeOptions}
              onChange={(e) => setServiceTypeId(e.value ?? null)}
              showClear
              className={classNames({
                sidebar_filter_active: serviceTypeId,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      t,
      basicFiltersActiveFilterCount,
      nameFilter,
      productTypeId,
      serviceTypeId,
      productTypeOptions,
      serviceTypeOptions,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setNameFilter('');
    setProductTypeId(null);
    setServiceTypeId(null);
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
