import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import { Tariff } from '../../../../../types/api/tariffs';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';

type Props = {
  visible: boolean;
  data: Tariff;
  onHide: () => void;
  reloadTariffs: () => void;
};

function DeleteDialog({
  visible,
  data,
  onHide,
  reloadTariffs,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { toastRef } = useContext(ToastContext);

  const {
    data: deleteData,
    error: deleteError,
    isLoading: isDeleteLoading,
    reload: deleteReload,
  } = useAxiosHook(
    {
      url: `/tariffs/${data?.id}`,
      method: 'DELETE',
    },
    {
      skipWhen: true,
    }
  );

  const prevDeleteData = usePrevious(deleteData);
  const prevDeleteError = usePrevious(deleteError);

  useEffect(() => {
    if (!deleteData || deleteData === prevDeleteData) {
      return;
    }

    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        t('Tariff {{name}} was deleted successfully.', {
          name: data?.name ?? '',
        })
      );
    }

    reloadTariffs();
    onHide();
  }, [
    deleteData,
    onHide,
    reloadTariffs,
    prevDeleteData,
    data?.name,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (!deleteError || deleteError === prevDeleteError) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while trying to delete tariff {{name}}.', {
          name: data?.name ?? '',
        })
      );
    }

    onHide();
  }, [deleteError, onHide, prevDeleteError, data?.name, t, toastRef]);

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="button"
        label={isDeleteLoading ? t('Deleting...') : t('Delete')}
        disabled={isDeleteLoading}
        onClick={() => deleteReload()}
        className="p-button-danger"
      />
    </>
  );

  return (
    <Dialog
      header={t('Deleting {{name}}', { name: data?.name ?? '' })}
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      onHide={onHide}
      style={{ width: 480, maxWidth: '100%' }}
    >
      <p className="p-m-0">
        {t('Are you sure you want to delete tariff {{name}}?', {
          name: data?.name ?? '',
        })}
      </p>

      <p className="p-m-0 p-mt-2">{t('This action cannot be undone.')}</p>
    </Dialog>
  );
}

export default DeleteDialog;
