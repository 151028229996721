import './ViewTerminalDialog.scss';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { SmartPOSTerminals } from '../../../../../types/api/smartPosTerminals';

type Props = {
  data: SmartPOSTerminals;
};

function ViewTerminalContent({ data }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('General information')}</h4>
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td>
              <b>{t('Name')}</b>
            </td>
            <td>{data?.username ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Terminal ID')}</b>
            </td>
            <td>{data?.terminalId ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Status')}</b>
            </td>
            <td>{data?.statusId ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Activation Code')}</b>
            </td>
            <td>{data?.activationCode ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Pin')}</b>
            </td>
            <td>{data?.pin ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Device')}</b>
            </td>
            <td>{data?.deviceName ?? '-'}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Created Date')}</b>
            </td>
            <td>
              {data?.createdAt
                ? dayjs(data.createdAt).format('DD MMM YYYY, HH:mm:ss')
                : '-'}
            </td>
          </tr>

          <tr>
            <td>
              <b>{t('Created By')}</b>
            </td>
            <td>{data?.createdBy}</td>
          </tr>

          <tr>
            <td>
              <b>{t('Modified Date')}</b>
            </td>
            <td>
              {data?.modifiedAt
                ? dayjs(data.modifiedAt).format('DD MMM YYYY, HH:mm:ss')
                : '-'}
            </td>
          </tr>

          <tr>
            <td>
              <b>{t('Modified By')}</b>
            </td>
            <td>{data?.modifiedBy}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default ViewTerminalContent;
