import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { getOrderStatusConfig } from '../../../../../../../configs/orders';
import { getProductTypeLabels } from '../../../../../../../configs/product';
import { CourierReceptionCollectedCollection } from '../../../../../../../types/api/orders';
import { ContextMenuItem } from '../../../../../../../types/primereact';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../../../../../types/util';
import { noColumnStyle } from '../../../../../../../utils/constants/tables';
import { dateCell } from '../../../../../../../utils/helpers/dataTable';
import { contextMenuModel } from '../../../../../../../utils/helpers/primereact';

export type Row = Unpacked<CourierReceptionCollectedCollection>;

type Column = TableColumns<
  Pick<
    Row,
    | 'serial_number'
    | 'status_id'
    | 'order_reception_date'
    | 'product_id'
    | 'sender_name'
    | 'sender_place_name'
    | 'recipient_place_name'
    | 'warehouseman_name'
  >
>;

export const tableStorageKey =
  'warehouse_courierShipmentReceptionNewShimpents_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): TableColumnHeadersMap<Column> {
  return {
    no: t('No.'),
    serial_number: t('Serial No.'),
    status_id: t('Status'),
    order_reception_date: t('Received At'),
    product_id: t('Product'),
    sender_name: t('Sender'),
    sender_place_name: t('Pickup place'),
    recipient_place_name: t('Delivery Location'),
    warehouseman_name: t('Received by.SHE'),
  };
}

export function additionalColumnProperties(t: TFunction) {
  const orderStatusConfig = getOrderStatusConfig(t);

  return (column: Column): ColumnProps => {
    switch (column) {
      case 'no':
        return noColumnStyle;

      case 'status_id':
        return {
          body: (row: Row) => orderStatusConfig[row[column]]?.label,
          style: { width: 300 },
          bodyStyle: { width: 300 },
        };

      case 'serial_number':
        return {
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'order_reception_date':
        return {
          body: (row: Row) => dateCell(row[column]),
          style: { width: 200 },
          bodyStyle: { width: 200 },
        };

      case 'product_id':
        return {
          body: (row: Row) => getProductTypeLabels(t)[row[column]],
          style: { width: 150 },
          bodyStyle: { width: 150 },
        };

      case 'sender_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'sender_place_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };

      case 'recipient_place_name':
      case 'warehouseman_name':
        return {
          style: { width: 250 },
          bodyStyle: { width: 250 },
        };
    }
  };
}

export function generateContextMenu({
  t,
  onViewClick,
  isReceiveShown,
  onReceiveClick,
  isCancelShown,
  onCancelClick,
}: {
  t: TFunction;
  onViewClick: () => void;
  isReceiveShown: boolean;
  onReceiveClick: () => void;
  isCancelShown: boolean;
  onCancelClick: () => void;
}): ContextMenuItem[] {
  const viewOption: ContextMenuItem = {
    label: t('View Details'),
    icon: 'fas fa-search',
    command: onViewClick,
  };

  const receiveOption: ContextMenuItem = {
    label: t('Receive'),
    icon: 'fas fa-barcode',
    command: onReceiveClick,
  };

  const cancelOption: ContextMenuItem = {
    label: t('Cancel reception'),
    icon: 'fas fa-ban',
    command: onCancelClick,
  };

  return contextMenuModel([
    [[true, viewOption]],
    [[isReceiveShown, receiveOption]],
    [[isCancelShown, cancelOption]],
  ]);
}
