import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { Tariff } from '../../../../../types/api/tariffs';
import { yupRequiredField } from '../../../../../utils/helpers/yup';

export type FormFields = {
  name: string;
  ruleGroups: Array<{
    operator: string;
    ordinalNumber: number;
    rules: {
      rulePropertyId: number | undefined;
      ruleConditionId: number | undefined;
      value: string;
      ruleOperator: string;
      ordinalNumber: number;
    }[];
  }>;
};

export function getDefaultValues(
  isEditDialog: boolean,
  data: Tariff | undefined
) {
  return {
    name: isEditDialog ? data?.name ?? '' : '',
    ruleGroups: [
      {
        operator: 'OR',
        ordinalNumber: 1,
        rules: [
          {
            rulePropertyId: undefined, // weight
            ruleConditionId: undefined, // graterThenOrEqual
            value: '',
            ruleOperator: 'AND',
            ordinalNumber: 1,
          },
          {
            rolePropertyId: undefined, // weight
            ruleConditionId: undefined, // lessThenOrEqual
            value: '',
            ruleOperator: 'OR', // this will be ignored since is the last item in the rule-set
            ordinalNumber: 2,
          },
        ],
      },
    ],
  };
}

export function toApiData(values: FormFields): Partial<FormFields> {
  return values;
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    name: Yup.string().required(yupRequiredField(t, t('Tariff'))),
    ruleGroups: Yup.array()
      .required(yupRequiredField(t, t('Payments')))
      .min(1, yupRequiredField(t, t('Payments'))),
  });
}

export const Operators = [
  {
    label: 'AND',
    value: 'AND',
  },
  { label: 'OR', value: 'OR' },
];

export const RoleOptions = [
  {
    label: 'Weight',
    value: 'Weight',
  },
  { label: 'Volume', value: 'Volume' },
  { label: 'CodAmount', value: 'CodAmount' },
  { label: 'Return Document', value: 'Return Document' },
];
