import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { WithPaginationAndSummary } from '../../../types/api';
import { SmartPOSTerminals } from '../../../types/api/smartPosTerminals';
import { EntityIdRouteParams } from '../../../types/routing';
import {
  createGuard,
  deleteGuard,
  editGuard,
  viewGuard,
} from '../../../utils/constants/auth/smartPosTerminals';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import AddEditDialog from './Dialogs/AddEdit/AddEditDialog';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import PreviewDialog from './Dialogs/Preview/PreviewDialog';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './SmartPosTerminals.functions';
import useTableFilters from './useTableFilters';

function SmartPosTerminals() {
  const { id } = useParams<EntityIdRouteParams>();
  const { t } = useTranslation();
  const pageTitle = t('Smart POS Terminals');
  usePageTitle(pageTitle);
  const viewSPTGuard = useEndpointGuard(viewGuard);
  const createSPTGuard = useEndpointGuard(createGuard);
  const editSPTGuard = useEndpointGuard(editGuard);
  const deleteSPTGuard = useEndpointGuard(deleteGuard);
  const [action, setAction] = useState<string>('');
  const [caller, setCaller] =
    useState<'group-actions' | 'context-menu'>('context-menu');
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selectionMultiple,
    setSortField,
    setSortOrder,
    contextMenuSelection,
    setSelectionMultiple,
    setContextMenuSelection,
  } = useTableState<SmartPOSTerminals>(tableStorageKey);

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { data, isLoading, reload, error } = useAxiosHook<
    WithPaginationAndSummary<SmartPOSTerminals[], { redemption: string }>
  >({
    url: '/smart-pos-terminals' + queryString(httpQueryObject(httpFiltersObj)),
  });

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'prices',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          setCaller,
          viewSPTGuard,
          editSPTGuard,
          deleteSPTGuard
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          setCaller,
          viewSPTGuard,
          editSPTGuard,
          deleteSPTGuard
        )}
      />,
    ],
    [
      columns,
      t,
      setContextMenuSelection,
      setAction,
      setCaller,
      viewSPTGuard,
      editSPTGuard,
      deleteSPTGuard,
    ]
  );

  const {
    show: showViewDialog,
    hide: hideViewDialog,
    isVisible: isViewDialogVisible,
  } = useRouteDialog(
    RoutePaths.SmartPosTerminals,
    constructIdRoute(
      RoutePaths.ViewSmartPosTerminals,
      id ?? contextMenuSelection?.id
    ),
    viewSPTGuard
  );

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.SmartPosTerminals,
    constructIdRoute(
      RoutePaths.EditSmartPosTerminals,
      id ?? contextMenuSelection?.id
    ),
    editSPTGuard
  );

  const {
    show: showAddDialog,
    hide: hideAddDialog,
    isVisible: isAddDialogVisible,
  } = useRouteDialog(
    RoutePaths.SmartPosTerminals,
    RoutePaths.AddSmartPosTerminals,
    createSPTGuard
  );

  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    isVisible: isDeleteDialogVisible,
  } = useRouteDialog(
    RoutePaths.SmartPosTerminals,
    constructIdRoute(
      RoutePaths.DeleteSmartPosTerminals,
      id ?? contextMenuSelection?.id
    ),
    deleteSPTGuard
  );

  const { data: terminalData, isLoading: isTerminalDataLoading } =
    useAxiosHook<SmartPOSTerminals>(
      {
        url: `/smart-pos-terminals/${id ?? contextMenuSelection?.id}`,
      },
      {
        skipWhen:
          (!id && !contextMenuSelection?.id) ||
          (!isAddDialogVisible && !isEditDialogVisible && !isViewDialogVisible),
      }
    );

  useEffect(() => {
    if (action && contextMenuSelection) {
      setCaller('context-menu');

      if (action === 'view-details') {
        showViewDialog();
      }
      if (action === 'add') {
        showAddDialog();
      }
      if (action === 'edit') {
        showEditDialog();
      }
      if (action === 'delete-order') {
        showDeleteDialog();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    setAction,
    showViewDialog,
    showEditDialog,
    showAddDialog,
    showDeleteDialog,
  ]);

  function handleCreateEditHide() {
    hideEditDialog();
    hideAddDialog();
    hideViewDialog();
  }

  function handleFromViewToEdit() {
    showEditDialog();
  }

  return (
    <div className="page smart-pos-terminals">
      <HeaderPages
        title={pageTitle}
        subtitle={t('View and manage Smart POS Terminals')}
        icon={faCreditCard}
      >
        <Button
          type="button"
          label={t('Add a terminal')}
          icon="fas fa-plus"
          onClick={showAddDialog}
          className="main-btn"
        />
      </HeaderPages>

      <PreviewDialog
        visible={isViewDialogVisible}
        onHide={handleCreateEditHide}
        {...(viewSPTGuard ? { onEdit: handleFromViewToEdit } : {})}
        data={terminalData}
        isLoading={isTerminalDataLoading}
      />

      <AddEditDialog
        visible={isAddDialogVisible || isEditDialogVisible}
        onHide={handleCreateEditHide}
        data={terminalData}
        isLoading={isTerminalDataLoading}
        isEditDialog={isEditDialogVisible}
        reloadTerminals={reload}
      />

      <DeleteDialog
        visible={isDeleteDialogVisible}
        data={
          caller === 'context-menu'
            ? contextMenuSelection
              ? [contextMenuSelection]
              : []
            : selectionMultiple
        }
        onHide={() => hideDeleteDialog()}
        reloadTerminals={reload}
        setSelectionMultiple={setSelectionMultiple}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={180}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columnOptions={columnOptions}
            columns={finalColumns}
            data={data}
            hasError={!!error}
            headerTitle=""
            isLoading={isLoading}
            rebuildTooltip
            reload={reload}
            headerFiltersCount={headerFiltersCount}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={180}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelectionMultiple}
            sortOrder={sortOrder}
            selection={selectionMultiple}
            storageString={tableStorageKey}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            exportToExcelButton
            // selectionMode="multiple"
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default SmartPosTerminals;
