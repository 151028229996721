import { Field, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../../../../hooks/useAxiosHook';
import { LabelValue } from '../../../../../../../types/options';
import { invalidDecimalPointCharactersRegex } from '../../../../../../../utils/constants/regex';
import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';
import {
  FormValues,
  RedemptionFieldValidation,
  RedemptionReceiver,
  RedemptionReceiverFieldValidation,
  SpecialProduct,
  UnregisteredClientId,
  getRedemptionReceiverTooltip,
  validateRedemptionField,
} from '../../CreateEditDialog.functions';

type Props = {
  isRedemptionReceiverDisabled: boolean;
  redemptionReceiverFieldValidation: RedemptionReceiverFieldValidation;
};

export function PackageAttributes({
  isRedemptionReceiverDisabled,
  redemptionReceiverFieldValidation,
}: Props) {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const { data: specialProductData } = useAxiosHook<SpecialProduct[]>(
    '/specialized_products'
  );

  const specialProductOptions = specialProductData?.map((product) => {
    return { label: product.name, id: product.id };
  });

  const redemptionFieldValidation = useMemo(
    () => validateRedemptionField(values.klient_od_id, values.vrednost),
    [values.klient_od_id, values.vrednost]
  );

  const redemptionReceiverTooltip = useMemo(
    () => getRedemptionReceiverTooltip(t, redemptionReceiverFieldValidation),
    [redemptionReceiverFieldValidation, t]
  );

  const redemptionReceiverOptions = useMemo<
    LabelValue<RedemptionReceiver>[]
  >(() => {
    return [
      { label: t('Sender'), value: RedemptionReceiver.Sender },
      { label: t('Orderer'), value: RedemptionReceiver.Orderer },
    ];
  }, [t]);

  const isRedemptionDisabled =
    redemptionFieldValidation ===
      RedemptionFieldValidation.UnregisteredSender &&
    (values.klient_do_id as any) != UnregisteredClientId.Recipient;

  return (
    <>
      <FieldWithErrors name="volumen" label={t('Volume')} className="p-col-6">
        <Field
          as={InputText}
          name="volumen"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              'volumen',
              e.target.value?.replace(invalidDecimalPointCharactersRegex, '')
            );
          }}
        />
      </FieldWithErrors>

      <FieldWithErrors name="otkup" label={t('Redemption')} className="p-col-6">
        <Field
          as={InputText}
          name="otkup"
          disabled={isRedemptionDisabled}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              'otkup',
              e.target.value?.replace(invalidDecimalPointCharactersRegex, '')
            );
          }}
          data-cy="redemption"
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="klient_otkup_id"
        label={t('Redemption receiver')}
        className="p-col-6"
      >
        <div
          className="disabled-redemption-receiver-tooltip"
          data-pr-tooltip={redemptionReceiverTooltip}
        >
          <Dropdown
            name="klient_otkup_id"
            inputId="klient_otkup_id"
            options={redemptionReceiverOptions}
            disabled={isRedemptionReceiverDisabled}
            placeholder={t('Not applicable')}
            value={
              isRedemptionReceiverDisabled
                ? redemptionReceiverFieldValidation ===
                  RedemptionReceiverFieldValidation.NoThirdParyOrderer
                  ? RedemptionReceiver.Sender
                  : null
                : values.klient_otkup_id
            }
            onChange={(e) => setFieldValue('klient_otkup_id', e.value)}
          />
        </div>
      </FieldWithErrors>

      <FieldWithErrors
        name="specijaliziran_proizvod_id"
        label={t('Specialized product')}
        className="p-col-6"
      >
        <Field
          as={Dropdown}
          filter
          filterPlaceholder={t('Search')}
          filterBy="label"
          placeholder={t('Select')}
          field="name"
          optionValue="id"
          options={specialProductOptions}
          name="specijaliziran_proizvod_id"
          inputId="specijaliziran_proizvod_id"
          showClear
          className="data-cy-special_product"
        />
      </FieldWithErrors>
    </>
  );
}
