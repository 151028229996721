import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../context/ToastContext';
import useAxios from '../../../../hooks/useAxios';
import useAxiosHook from '../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../hooks/useEndpointGuard';
import usePageTitle from '../../../../hooks/usePageTitle';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableState from '../../../../hooks/useTableState';
import { HubCollection } from '../../../../types/api/hubs';
import { Unpacked } from '../../../../types/util';
import * as warehousesGuards from '../../../../utils/constants/auth/warehouses';
import { formik_handleNewErrorFormatUponSubmission } from '../../../../utils/helpers';
import { queryString } from '../../../../utils/helpers/http';
import { httpQueryObject } from '../../../../utils/helpers/misc';
import { errorToast, successToast } from '../../../../utils/helpers/primereact';
import Table from '../../../DataTable/Table/Table';
import Flex from '../../../layout/flex/Flex';
import MainContent from '../../../layout/flex/MainContent';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import CreateEditDialog from './Dialogs/CreateEditDialog/CreateEditDialog';
import { toApiData } from './Dialogs/CreateEditDialog/CreateEditDialog.functions';
import DeleteHubDialog from './Dialogs/Delete/DeleteHubDialog';
import ViewDialog from './Dialogs/ViewDialog/ViewDialog';
import useTableFilters from './useTableFilters';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './WarehousesList.functions';

function WarehousesList() {
  const { t } = useTranslation();
  const createEditDialogFormRef = useRef(null);
  const { toastRef } = useContext(ToastContext);
  const [isViewDialogShown, setIsViewDialogShown] = useState(false);
  const [isCreateEditDialogShown, setIsCreateEditDialogShown] = useState(false);
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const [isEditDialog, setIsEditDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [step, setStep] = useState(1);
  const [action, setAction] = useState<string>('');
  usePageTitle(t('Warehouses List'));

  const readGuard = useEndpointGuard(warehousesGuards.readHub);
  const createGuard = useEndpointGuard(warehousesGuards.createHub);
  const editGuard = useEndpointGuard(warehousesGuards.editHub);
  const deleteGuard = useEndpointGuard(warehousesGuards.deleteHub);

  const [isNameUsed, setIsNameUsed] = useState(false);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Unpacked<HubCollection>>(tableStorageKey);

  const { headerFiltersCount, filters, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'employees',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          readGuard,
          editGuard,
          deleteGuard
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          readGuard,
          editGuard,
          deleteGuard
        )}
      />,
    ],
    [columns, t, setContextMenuSelection, readGuard, editGuard, deleteGuard]
  );

  const { data, isLoading, reload, error } = useAxiosHook<HubCollection>(
    '/hubs' + queryString(httpQueryObject(httpFiltersObj))
  );

  const { reload: reloadData, isLoading: isFormSubmissionRequestLoading } =
    useAxios();

  useEffect(() => {
    if (!readGuard) {
      setIsViewDialogShown(false);
    }
  }, [readGuard]);

  useEffect(() => {
    if (!createGuard && isCreateEditDialogShown && !isEditDialog) {
      setIsCreateEditDialogShown(false);
    }
  }, [createGuard, isCreateEditDialogShown, isEditDialog]);

  useEffect(() => {
    if (!editGuard && isCreateEditDialogShown && isEditDialog) {
      setIsCreateEditDialogShown(false);
    }
  }, [editGuard, isCreateEditDialogShown, isEditDialog]);

  useEffect(() => {
    if (!deleteGuard) {
      setIsDeleteDialogShown(false);
    }
  }, [deleteGuard]);

  function handleCreateHubBtnClick() {
    setDialogData({});
    setIsCreateEditDialogShown(true);
  }

  // Context menu functions
  const handleCMViewClick = useCallback(() => {
    reloadData({
      url: `/hubs/${contextMenuSelection?.id}`,
      successCallback: (data: any) => {
        setDialogData(data.data);
        setIsViewDialogShown(true);
      },
    });
  }, [contextMenuSelection, reloadData]);

  const handleCMEditClick = useCallback(() => {
    reloadData({
      url: `/hubs/${contextMenuSelection?.id}`,
      successCallback: (data: any) => {
        setDialogData(data.data);
        setIsEditDialog(true);
        setIsCreateEditDialogShown(true);
      },
    });
  }, [contextMenuSelection, reloadData]);

  const handleCMDeleteClick = useCallback(() => {
    setIsDeleteDialogShown(true);
  }, []);

  function handleCreateEditDialogHide() {
    setIsEditDialog(false);
    setIsCreateEditDialogShown(false);
  }

  function handleViewDialogHide() {
    setIsViewDialogShown(false);
    setDialogData({});
  }

  function handleDeleteDialogHide() {
    setIsDeleteDialogShown(false);
  }

  function handleViewDialogEditBtnClick(id: any) {
    setIsViewDialogShown(false);
    setIsEditDialog(true);
    reloadData({
      url: `/hubs/${id}`,
      successCallback: (data: any) => {
        setDialogData(data.data);
        setIsEditDialog(true);
        setIsCreateEditDialogShown(true);
      },
    });
  }

  function handleHubCreate(name: string, isSuccess: boolean) {
    if (isSuccess) {
      successToast(
        toastRef,
        t('Success'),
        t('Warehouse {{name}} has been added successfully', { name: name })
      );
    } else {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while adding {{name}}.', { name: name })
      );
    }

    setIsCreateEditDialogShown(false);
    reload();
  }

  function handleHubEdit(name: string, isSuccess: boolean) {
    if (isSuccess) {
      successToast(
        toastRef,
        t('Success'),
        t('Warehouse {{name}} has been edited successfully', { name: name })
      );
    } else {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while editing {{name}}.', { name: name })
      );
    }

    setIsCreateEditDialogShown(false);
    reload();
  }

  function handleCreateEditDialogFormSubmission(values: any) {
    if (step < 3) {
      let nextStep = step + 1;
      setStep(nextStep);
    } else {
      const allowedKeys = ['ime'];
      const allowedValues = {
        already_in_use: t('This value is already in use'),
      };
      if (isEditDialog) {
        reloadData({
          url: `/hubs/${contextMenuSelection?.id}`,
          payload: toApiData(values),
          method: 'PUT',
          successCallback: () => {
            handleHubEdit(values.ime, true);
          },
          errorCallback: (err: any) => {
            const formErrors = formik_handleNewErrorFormatUponSubmission(
              err?.response,
              createEditDialogFormRef,
              allowedKeys,
              allowedValues
            );
            if (formErrors) {
              setIsNameUsed(true);
              return;
            } else {
              handleHubEdit(values.ime, false);
            }
          },
        });
      } else {
        reloadData({
          url: '/hubs',
          payload: toApiData(values),
          method: 'POST',
          successCallback: () => {
            handleHubCreate(values.ime, true);
          },
          errorCallback: (err: any) => {
            const formErrors = formik_handleNewErrorFormatUponSubmission(
              err?.response,
              createEditDialogFormRef,
              allowedKeys,
              allowedValues
            );
            if (formErrors) {
              setIsNameUsed(true);
              return;
            } else {
              handleHubCreate(values.ime, false);
            }
          },
        });
      }
    }
  }

  useEffect(() => {
    if (action && contextMenuSelection) {
      if (action === 'view-details') {
        handleCMViewClick();
      }
      if (action === 'edit') {
        handleCMEditClick();
      }
      if (action === 'delete') {
        handleCMDeleteClick();
      }

      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    setAction,
    handleCMViewClick,
    handleCMEditClick,
    handleCMDeleteClick,
  ]);

  return (
    <div className="page hubs-page">
      <HeaderPages
        title={t('Warehouses List')}
        subtitle={t('View and manage warehouses')}
        icon={faNetworkWired}
      >
        {createGuard && (
          <Button
            type="button"
            label={t('Create warehouse')}
            icon="fas fa-plus"
            className="main-btn"
            disabled={isLoading}
            onClick={handleCreateHubBtnClick}
            data-cy="add-btn"
          />
        )}
      </HeaderPages>

      <CreateEditDialog
        formRef={createEditDialogFormRef}
        isShown={isCreateEditDialogShown}
        isEditDialog={isEditDialog}
        data={dialogData}
        onHide={handleCreateEditDialogHide}
        onFormSubmision={handleCreateEditDialogFormSubmission}
        isFormSubmissionRequestLoading={isFormSubmissionRequestLoading}
        step={step}
        setStep={setStep}
        isNameUsed={isNameUsed}
        setIsNameUsed={setIsNameUsed}
      />

      <ViewDialog
        isShown={isViewDialogShown}
        data={dialogData}
        onHide={handleViewDialogHide}
        onEditBtnClick={handleViewDialogEditBtnClick}
      />

      <DeleteHubDialog
        visible={isDeleteDialogShown}
        onHide={handleDeleteDialogHide}
        selection={contextMenuSelection}
        reloadHubLists={reload}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={180}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={180}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            exportToCSVButton
            onExportToCSVButtonClick={() =>
              (tableRef?.current as any)?.exportCSV()
            }
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}
export default WarehousesList;
