import { TFunction } from 'react-i18next';

export function getNotificationChannel(t: TFunction) {
  return [
    {
      id: 1,
      title: t('Email'),
      description: t('Notify via e-mail channel'),
    },
    {
      id: 2,
      title: t('SMS'),
      description: t('Notify via SMS channel'),
    },
  ];
}

export function getNotificationType(t: TFunction) {
  return [
    // {
    //   id: 1,
    //   title: 'Pickup Attempt',
    //   description:
    //     'During the day, a courier will make an attempt to pickup the parcel',
    // },
    {
      id: 2,
      title: t('Pickup Done'),
      description: t('Parcel was successfully retrieved by the courier'),
    },
    {
      id: 3,
      title: t('Delivery Attempt'),
      description: t(
        'During the day, a courier will make an attempt to deliver the parcel'
      ),
    },
    {
      id: 4,
      title: t('Delivery Done'),
      description: t('Parcel was successfully delivered by the courier'),
    },
    {
      id: 5,
      title: t('Order Created'),
      description: t('An order was successfully created'),
    },
    {
      id: 6,
      title: t('Batch Order Created'),
      description: t('Batch orders were successfully created'),
    },
    {
      id: 7,
      title: t('Batch Pickup Done'),
      description: t(
        'Multiple parcels were successfully retrieved by the courier from same sender'
      ),
    },
    {
      id: 8,
      title: t('In Delivery Warehouse'),
      description: t('Parcel arrived in delivery warehouse'),
    },
    {
      id: 9,
      title: t('In Pickup Warehouse'),
      description: t('Parcel received in pickup warehouse'),
    },
    {
      id: 13,
      title: t('In a deposit locker'),
      description: t('Parcel was placed in a deposit locker'),
    },
    {
      id: 14,
      title: t('In a pickup locker'),
      description: t('Parcel was placed in a pickup locker'),
    },
  ];
}

export function getNotificationRecipient(t: TFunction) {
  return [
    {
      id: 2,
      name: 'orderer',
      title: t('Orderer'),
      description: t('Notify orderer'),
    },
    {
      id: 3,
      name: 'sender',
      title: t('Sender'),
      description: t('Notify sender'),
    },
    {
      id: 4,
      name: 'recipient',
      title: t('Recipient'),
      description: t('Notify recipient'),
    },
  ];
}
