import { faPortrait } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import bulkOrderSound from '../../../../../assets/sounds/bulk-order.wav';
import {
  getAssignmentAndReceptionStatusSeveritySound,
  getWarehouseOrderReceptionStatusConfig,
} from '../../../../../configs/orders';
import ToastContext from '../../../../../context/ToastContext';
import { IntBool } from '../../../../../enums/booleans';
import { FileTypes } from '../../../../../enums/files';
import {
  WarehouseAssignmentAndReceptionStatusSeverity,
  WarehouseOrderReceptionStatus,
} from '../../../../../enums/orders';
import { UserRole } from '../../../../../enums/users';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../../hooks/useEndpointGuard';
import usePageTitle from '../../../../../hooks/usePageTitle';
import usePreventWindowClose from '../../../../../hooks/usePreventWindowClose';
import usePrevious from '../../../../../hooks/usePrevious';
import useRouteDialog from '../../../../../hooks/useRouteDialog';
import useSearchQueryParam from '../../../../../hooks/useSearchQueryParam';
import useTableState from '../../../../../hooks/useTableState';
import useToastMessage from '../../../../../hooks/useToastMessage';
import { ConfigCollection } from '../../../../../types/api/configs';
import {
  AvailableCouriersCollection,
  AvailableCouriersCollectionQueryParams,
} from '../../../../../types/api/couriers';
import {
  CourierReceptionCollectedCollection,
  CourierReceptionCollectedCollectionQueryParams,
  CourierReceptionReportQueryParams,
  CourierReceptionReturnedCollection,
  CourierReceptionReturnedCollectionQueryParams,
  CourierSummaryCollection,
  CourierSummaryCollectionQueryParams,
  DeleteCourierReception,
  UndoReceivedShipmentQueryParams,
  UpdateCourierReception,
  UpdateCourierReceptionRequestPayload,
} from '../../../../../types/api/orders';
import { RegionCollection } from '../../../../../types/api/regions';
import { Numeric } from '../../../../../types/general';
import { EntityIdRouteParams } from '../../../../../types/routing';
import { Unpacked } from '../../../../../types/util';
import { configCollectionGuard } from '../../../../../utils/constants/auth/_api/configs';
import { regionCollectionGuard } from '../../../../../utils/constants/auth/_api/regions';
import { receiveShipmentGuard } from '../../../../../utils/constants/auth/courierShipmentReception';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../../utils/constants/routePaths';
import {
  findSingleConfig,
  getBoolConfigValue,
} from '../../../../../utils/helpers/api/configs';
import { base64ToAudio, chainAudio } from '../../../../../utils/helpers/audio';
import { downloadFile, getFileName } from '../../../../../utils/helpers/files';
import { sequential } from '../../../../../utils/helpers/functions';
import { queryString } from '../../../../../utils/helpers/http';
import { tryInt } from '../../../../../utils/helpers/parse';
import {
  errorToast,
  successToast,
  warnToast,
} from '../../../../../utils/helpers/primereact';
import { getSearchQueryParam } from '../../../../../utils/helpers/searchQuery';
import Flex from '../../../../layout/flex/Flex';
import MainContent from '../../../../layout/flex/MainContent';
import DetailsSection from '../../../Components/DetailsSection/DetailsSection';
import HeaderPages from '../../../Components/HeaderPages/HeaderPages';
import ViewActiveOrderDialog from '../../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../../../Orders/Orders.functions';
import CommonStateContext from '../../_AssignmentAndReception/CommonStateContext';
import CreateWorkOrderDialog from '../../_AssignmentAndReception/Dialogs/Create/WorkOrder/CreateWorkOrderDialog';
import Barcode from '../../_AssignmentAndReception/Sidebar/Barcode';
import Filters from '../../_AssignmentAndReception/Sidebar/Filters';
import {
  CandidatesFilter,
  WarehousePage,
} from '../../_AssignmentAndReception/types';
import useCommonState from '../../_AssignmentAndReception/useCommonState';
import Stats from './Sidebar/Stats';
import NewShipments from './Tabs/NewShipments/NewShipments';
import { tableStorageKey as NewShipmentsStorageKey } from './Tabs/NewShipments/NewShipments.functions';
import ReturnedShipments from './Tabs/ReturnedShipments/ReturnedShipments';
import { tableStorageKey as ReturnedShipmentsStorageKey } from './Tabs/ReturnedShipments/ReturnedShipments.functions';

function CourierShipmentReception(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Shipment Reception From Courier'));

  // Prevent entire page being closed unintentionally.
  usePreventWindowClose();

  const canLoadConfigs = useEndpointGuard(configCollectionGuard);
  const canLoadRegions = useEndpointGuard(regionCollectionGuard);

  const canLoadSounds = canLoadConfigs && canLoadRegions;

  const location = useLocation();

  const { toastRef, bottomRightToastRef } = useContext(ToastContext);

  const barcodeGuard = useEndpointGuard(receiveShipmentGuard);

  const state = useCommonState({
    page: WarehousePage.CourierShipmentReception,
  });
  const {
    courierFilter,
    candidatesFilter,
    debouncedSerialNumberFilter,
    isCreateWorkOrderDialogShown,
    handleCreateWorkOrderDialogSuccess,
    handleCreateWorkOrderDialogHide,
    isDateValid,
    courierWorkOrderId,
    hasCourierWorkOrder,
    nativeHubId,
  } = state;

  const [activeIndex, setActiveIndex] = useState(
    () => tryInt(getSearchQueryParam(location.search, 'activeTab')) ?? 0
  );

  useSearchQueryParam('activeTab', String(activeIndex));

  const newShipmentsTableState = useTableState<
    Unpacked<CourierReceptionCollectedCollection>
  >(NewShipmentsStorageKey);

  const returnedShipmentsTableState = useTableState<
    Unpacked<CourierReceptionReturnedCollection>
  >(ReturnedShipmentsStorageKey);

  const couriersCollectionRequest = useAxiosHook<AvailableCouriersCollection>(
    '/couriers/available' +
      queryString<AvailableCouriersCollectionQueryParams>({
        is_assigned_to_region: IntBool.True,
        user_role_id: UserRole.Courier,
      })
  );

  const newShimpentsRequest = useAxiosHook<CourierReceptionCollectedCollection>(
    '/orders/courier/reception/collected' +
      queryString<CourierReceptionCollectedCollectionQueryParams>({
        work_order_id: courierWorkOrderId ?? '',
        show_candidates:
          isDateValid && candidatesFilter === CandidatesFilter.Hidden ? 0 : 1,
        sort_is_candidate:
          candidatesFilter === CandidatesFilter.Last ? 'asc' : 'desc',
        serial_number: debouncedSerialNumberFilter,
        page: newShipmentsTableState.page,
        limit: newShipmentsTableState.limit,
      }),
    { skipWhen: !hasCourierWorkOrder }
  );

  const returnedShipmentsRequest =
    useAxiosHook<CourierReceptionReturnedCollection>(
      '/orders/courier/reception/returned' +
        queryString<CourierReceptionReturnedCollectionQueryParams>({
          work_order_id: courierWorkOrderId ?? '',
          show_candidates:
            isDateValid && candidatesFilter === CandidatesFilter.Hidden ? 0 : 1,
          sort_is_candidate:
            candidatesFilter === CandidatesFilter.Last ? 'asc' : 'desc',
          serial_number: debouncedSerialNumberFilter,
          page: returnedShipmentsTableState.page,
          limit: returnedShipmentsTableState.limit,
        }),
      { skipWhen: !hasCourierWorkOrder }
    );

  const statsRequest = useAxiosHook<CourierSummaryCollection>(
    '/orders/courier/summary' +
      queryString<CourierSummaryCollectionQueryParams>({
        work_order_id: courierWorkOrderId ?? '',
        hub_id: nativeHubId!,
      }),
    { skipWhen: !hasCourierWorkOrder }
  );

  useToastMessage(undefined, statsRequest.error, {
    error: {
      summary: t('An error occured while reading stats data.'),
    },
  });

  const { data: configs, isLoading: isConfigsLoading } =
    useAxiosHook<ConfigCollection>('/configs', {
      skipWhen: !canLoadSounds,
    });

  const { data: regions, isLoading: isRegionsLoading } =
    useAxiosHook<RegionCollection>('/regions', {
      skipWhen: !canLoadSounds,
    });

  const isRegionSoundsLoading = isConfigsLoading || isRegionsLoading;

  const regionSounds = useMemo<Record<string, string>>(() => {
    const configPlayRegionSound = findSingleConfig(
      configs,
      'Config',
      'PlayRegionSound'
    );

    const shouldPlayRegionSound =
      configPlayRegionSound && getBoolConfigValue(configPlayRegionSound);

    if (!regions || !shouldPlayRegionSound) {
      return {};
    }

    return Object.fromEntries(
      regions.data
        .filter((r) => !!r.sound)
        .map((r) => [r.id, r.sound as string])
    );
  }, [configs, regions]);

  const bulkOrderAudio = useMemo<HTMLAudioElement | undefined>(() => {
    const configPlayQuantitySound = findSingleConfig(
      configs,
      'Config',
      'PlayQuantitySound'
    );

    return configPlayQuantitySound &&
      getBoolConfigValue(configPlayQuantitySound)
      ? new Audio(bulkOrderSound)
      : undefined;
  }, [configs]);

  const {
    data: receiveOrderData,
    error: receiveOrderError,
    reload: receiveOrderReload,
  } = useAxiosHook<UpdateCourierReception>();

  const handleValidBarcodeScan = useCallback(
    (barcode: string) => {
      if (!barcode.length) {
        return;
      }

      const data: UpdateCourierReceptionRequestPayload = {
        work_order_id: courierWorkOrderId!,
        serial_number: barcode,
      };

      receiveOrderReload({
        url: '/orders/courier/reception',
        method: 'PUT',
        data,
      });
    },
    [receiveOrderReload, courierWorkOrderId]
  );

  const pickupDataPrevious = usePrevious(receiveOrderData);

  useToastMessage(undefined, receiveOrderError, {
    error: {
      summary: t('An error occured while trying to receive shipment.'),
    },
  });

  useEffect(() => {
    if (!receiveOrderData || receiveOrderData === pickupDataPrevious) {
      return;
    }

    const warehouseOrderReceptionStatusConfig =
      getWarehouseOrderReceptionStatusConfig(t);

    const status = receiveOrderData.assignment_status_id;

    const config = warehouseOrderReceptionStatusConfig[status];

    const recipientRegionId = receiveOrderData.recipient_region_id;

    switch (config.severity) {
      case WarehouseAssignmentAndReceptionStatusSeverity.Info:
        successToast(toastRef, t('Success'), config.message);
        break;

      case WarehouseAssignmentAndReceptionStatusSeverity.Warning:
        warnToast(toastRef, t('Warning'), config.message);
        break;

      case WarehouseAssignmentAndReceptionStatusSeverity.Error:
        errorToast(toastRef, t('Error'), config.message);
        break;
    }

    const receptionStatusSound = getAssignmentAndReceptionStatusSeveritySound(
      config.severity
    );

    // Skip region sounds for shipments that weren't registered before their reception
    const regionSound =
      status !== WarehouseOrderReceptionStatus.EmptyOrder &&
      recipientRegionId &&
      regionSounds[recipientRegionId]
        ? base64ToAudio(regionSounds[recipientRegionId])
        : undefined;

    const bulkOrderAudioSound =
      parseInt(receiveOrderData.quantity) > 1 ? bulkOrderAudio : undefined;

    const sounds: HTMLAudioElement[] = [
      receptionStatusSound,
      bulkOrderAudioSound,
      regionSound,
    ].filter((s): s is HTMLAudioElement => !!s);

    chainAudio(sounds);

    if (
      config.severity !== WarehouseAssignmentAndReceptionStatusSeverity.Error
    ) {
      newShimpentsRequest.reload();
      returnedShipmentsRequest.reload();
      statsRequest.reload();
    }
  }, [
    bulkOrderAudio,
    newShimpentsRequest,
    pickupDataPrevious,
    receiveOrderData,
    regionSounds,
    returnedShipmentsRequest,
    statsRequest,
    t,
    toastRef,
  ]);

  const { id } = useParams<EntityIdRouteParams>();

  const {
    show: showViewOrderDialog,
    hide: hideViewOrderDialog,
    isVisible: isViewOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.CourierShipmentReceptions,
    constructIdRoute(
      RoutePaths.ViewCourierShipmentReception,
      id ??
        (activeIndex === 0
          ? newShipmentsTableState.selection?.order_id
          : returnedShipmentsTableState.selection?.order_id)
    )
  );

  const { data: orderData, isLoading: isOrderDataLoading } =
    useAxiosHook<SingleOrder>(
      {
        url: `/orders/${id}`,
      },
      {
        skipWhen: !isViewOrderDialogVisible,
      }
    );

  const {
    data: cancelShipmentData,
    error: cancelShipmentError,
    reload: cancelShipmentReload,
  } = useAxiosHook<DeleteCourierReception>();

  useToastMessage(cancelShipmentData, cancelShipmentError, {
    success: {
      summary: t('Successfully cancelled reception for shipment {{id}}.', {
        id: cancelShipmentData?.serial_number ?? '',
      }),
      callback: () =>
        sequential(
          newShimpentsRequest.reload,
          returnedShipmentsRequest.reload,
          statsRequest.reload
        ),
    },
    error: {
      summary: t(
        'An error occured while trying to cancel the shipment reception.'
      ),
    },
  });

  const cancelShipment = useCallback(
    (orderID: Numeric) => {
      cancelShipmentReload({
        url:
          `/orders/courier/reception/${orderID}` +
          queryString<UndoReceivedShipmentQueryParams>({
            work_order_id: courierWorkOrderId!,
          }),
        method: 'DELETE',
      });
    },
    [cancelShipmentReload, courierWorkOrderId]
  );

  const receiveNewShipment = useCallback(() => {
    if (!newShipmentsTableState.selection?.serial_number) {
      return;
    }

    handleValidBarcodeScan(newShipmentsTableState.selection.serial_number);
  }, [handleValidBarcodeScan, newShipmentsTableState.selection?.serial_number]);

  const handleReturnedShipmentReceptionClick = useCallback(() => {
    if (!returnedShipmentsTableState.selection?.serial_number) {
      return;
    }

    handleValidBarcodeScan(returnedShipmentsTableState.selection.serial_number);
  }, [
    handleValidBarcodeScan,
    returnedShipmentsTableState.selection?.serial_number,
  ]);

  function handlePrintCourierReceiptBtnClick() {
    if (!hasCourierWorkOrder) {
      return;
    }

    const hubId = couriersCollectionRequest.data?.find(
      (c) => c.id === courierFilter
    )?.hub_id;

    if (!hubId) {
      return;
    }

    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/orders/courier/reception/pdf` +
        queryString<CourierReceptionReportQueryParams>({
          work_order_id: courierWorkOrderId!,
          hub_id: hubId,
        }),
      getFileName(
        t('CourierShipmentReception'),
        String(courierWorkOrderId),
        true
      ),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  return (
    <div className="page page-courier">
      <CommonStateContext.Provider value={state}>
        <HeaderPages
          title={t('Reception From Courier')}
          subtitle={t('Receive and preview new and returned orders')}
          icon={faPortrait}
        >
          <Button
            label={t('Print Courier Receipt')}
            icon="fas fa-print"
            className="main-btn"
            disabled={!hasCourierWorkOrder || !courierFilter}
            onClick={handlePrintCourierReceiptBtnClick}
            data-cy="create-btn"
          />
        </HeaderPages>

        <ViewActiveOrderDialog
          data={orderData}
          visible={isViewOrderDialogVisible}
          onHide={hideViewOrderDialog}
          isLoading={isOrderDataLoading}
        />

        <CreateWorkOrderDialog
          visible={isCreateWorkOrderDialogShown}
          onHide={handleCreateWorkOrderDialogHide}
          onSuccess={handleCreateWorkOrderDialogSuccess}
          couriers={couriersCollectionRequest.data}
          courierFilter={courierFilter}
        />

        <Flex direction="column">
          <Filters
            availableCouriersRequest={couriersCollectionRequest}
            filterHeight={220}
          />
          <DetailsSection>
            {barcodeGuard && (
              <Barcode
                onValidBarcodeScan={handleValidBarcodeScan}
                disabled={isRegionSoundsLoading && !isDateValid}
              />
            )}
            {statsRequest && <Stats request={statsRequest} />}
          </DetailsSection>

          <MainContent>
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
              className="table-tabview"
            >
              <TabPanel header={t('New Shipments')}>
                <NewShipments
                  isDateValid={isDateValid}
                  request={newShimpentsRequest}
                  statsRequest={statsRequest}
                  tableState={newShipmentsTableState}
                  cancelCallback={cancelShipment}
                  showOrderDialog={showViewOrderDialog}
                  receptionCallback={receiveNewShipment}
                  shouldDisplayData={hasCourierWorkOrder}
                />
              </TabPanel>

              <TabPanel header={t('Returned Shipments')}>
                <ReturnedShipments
                  isDateValid={isDateValid}
                  courierWorkOrderId={courierWorkOrderId}
                  request={returnedShipmentsRequest}
                  statsRequest={statsRequest}
                  tableState={returnedShipmentsTableState}
                  cancelCallback={cancelShipment}
                  showOrderDialog={showViewOrderDialog}
                  receptionCallback={handleReturnedShipmentReceptionClick}
                  shouldDisplayData={hasCourierWorkOrder}
                />
              </TabPanel>
            </TabView>
          </MainContent>
        </Flex>
      </CommonStateContext.Provider>
    </div>
  );
}

export default CourierShipmentReception;
