import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { renderIcon } from '../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';
import { smartPOSTerminalsStatuses } from './Dialogs/AddEdit/AddEditDialog.functions';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? dayjs().toDate()
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? dayjs().toDate()
  );
  const [nameFilter, setNameFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'username') ?? ''
  );
  const [deviceNameFilter, setDeviceNameFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'device_name') ?? ''
  );
  const [terminalIdFilter, setTerminalIdFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'terminal_id') ?? ''
  );
  const [statusIdFilter, setStatusIdFilter] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'status_id') ?? null
  );

  const statusesOptions = useMemo(() => smartPOSTerminalsStatuses(t), [t]);

  useEffect(() => {
    if (!setPage) {
      return;
    }
    setPage(1);
  }, [dateFrom, dateTo, setPage]);

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);
  const debouncedDeviceNameFilter = useDebounce(
    deviceNameFilter,
    debounceTimeout
  );
  const debouncedTerminalIdFilter = useDebounce(
    terminalIdFilter,
    debounceTimeout
  );

  const filtersArr = useMemo(
    () => [
      debouncedNameFilter,
      debouncedDeviceNameFilter,
      debouncedTerminalIdFilter,
      dateFrom,
      dateTo,
    ],
    [
      debouncedNameFilter,
      debouncedDeviceNameFilter,
      debouncedTerminalIdFilter,
      dateFrom,
      dateTo,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [
    setPage,
    debouncedNameFilter,
    debouncedTerminalIdFilter,
    dateFrom,
    dateTo,
  ]);

  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);
  useSearchQueryParam('username', debouncedNameFilter);
  useSearchQueryParam('device_name', debouncedDeviceNameFilter);
  useSearchQueryParam('terminal_id', debouncedTerminalIdFilter);
  useSearchQueryDropdownParam(
    'status_id',
    statusIdFilter,
    setStatusIdFilter,
    statusesOptions
  );

  const httpFiltersObj = useMemo(() => {
    const _dateFrom = dayjs(dateFrom as any);
    const _dateTo = dayjs(dateTo as any);
    return {
      date_from: httpDateFormat(_dateFrom),
      date_to: httpDateFormat(_dateTo),
      username: debouncedNameFilter,
      device_name: debouncedDeviceNameFilter,
      terminal_id: debouncedTerminalIdFilter,
      status_id: statusIdFilter,
      page: haveFiltersChanged ? 1 : page,
      limit,
    };
  }, [
    debouncedNameFilter,
    debouncedDeviceNameFilter,
    haveFiltersChanged,
    limit,
    page,
    debouncedTerminalIdFilter,
    dateFrom,
    dateTo,
    statusIdFilter,
  ]);

  const basicFiltersActiveFilterCount = Object.values({
    dateFrom,
    nameFilter,
    deviceNameFilter,
    terminalIdFilter,
    statusIdFilter,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                id="filter_date_from"
                value={dateFrom ?? undefined}
                monthNavigator
                yearNavigator
                dateFormat="dd/mm/yy"
                yearRange={`2009:${dayjs().format('YYYY')}`}
                maxDate={new Date()}
                onChange={(e) => setDateFrom(e.value as Date | null)}
                inputClassName={classNames({
                  sidebar_filter_active: dateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="filter_date_to"
                value={dateTo ?? undefined}
                onChange={(e) => {
                  setDateTo(e.value as Date | null);
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateTo,
                })}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_username">{t('Name')}</label>
            <InputText
              id="filter_username"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              className={classNames({
                sidebar_filter_active: nameFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_terminal_id">{t('Terminal ID')}</label>
            <InputText
              id="filter_terminal_id"
              value={terminalIdFilter}
              onChange={(e) => setTerminalIdFilter(e.target.value)}
              className={classNames({
                sidebar_filter_active: terminalIdFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="status_id_filter">{t('Status')}</label>
            <Dropdown
              id="status_id_filter"
              options={statusesOptions}
              value={statusIdFilter}
              onChange={(e: DropdownChangeParams) => setStatusIdFilter(e.value)}
              showClear
              className={classNames({
                sidebar_filter_active: statusIdFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_device_name">{t('Device')}</label>
            <InputText
              id="filter_device_name"
              value={deviceNameFilter}
              onChange={(e) => setDeviceNameFilter(e.target.value)}
              className={classNames({
                sidebar_filter_active: deviceNameFilter,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      t,
      basicFiltersActiveFilterCount,
      nameFilter,
      deviceNameFilter,
      terminalIdFilter,
      dateFrom,
      dateTo,
      statusIdFilter,
      statusesOptions,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setNameFilter('');
    setDeviceNameFilter('');
    setTerminalIdFilter('');
    setStatusIdFilter(null);
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
