import {
  addTariffsResourceGuard,
  deleteTariffsResourceGuard,
  editTariffsResourceGuard,
  tariffCollectionGuard,
  tariffsResourceGuard,
} from './_api/tariffs';

export const createGuard = [
  addTariffsResourceGuard,
  tariffCollectionGuard,
  tariffCollectionGuard,
];
export const editGuard = editTariffsResourceGuard;
export const deleteGuard = deleteTariffsResourceGuard;

export const routeGuard = [tariffsResourceGuard, tariffCollectionGuard];
