import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Tariff } from '../../../../../types/api/tariffs';

type Props = {
  data: Tariff | undefined;
};

function ViewTariffContent({ data }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('General information')}</h4>
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td>
              <b>{t('Name')}</b>
            </td>
            <td>{data?.name ?? '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Code')}</b>
            </td>
            <td>{data?.code ?? '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Created Date')}</b>
            </td>
            <td>
              {data?.created_at
                ? dayjs(data.created_at).format('DD MMM YYYY, HH:mm:ss')
                : '-'}
            </td>
          </tr>
          <tr>
            <td>
              <b>{t('Created By')}</b>
            </td>
            <td>{data?.created_by_full_name ?? '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Modified Date')}</b>
            </td>
            <td>
              {data?.modified_at
                ? dayjs(data?.modified_at).format('DD MMM YYYY, HH:mm:ss')
                : '-'}
            </td>
          </tr>
          <tr>
            <td>
              <b>{t('Modified By')}</b>
            </td>
            <td>{data?.modified_by_full_name ?? '-'}</td>
          </tr>
          <tr>
            <td>
              <b>{t('Description')}</b>
            </td>
            <td>{data?.description ?? '-'}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default ViewTariffContent;
