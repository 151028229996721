import classNames from 'classnames';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { HTMLAttributes, ReactNode, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarFiltersContext } from '../../../../context/SidebarFiltersContext';
import { useSidebarFiltersHeight } from '../hooks/useSidebarFilterHeight';
import styles from './CollapsibleFilterSection.module.scss';

type Props = {
  children: ReactNode;
  activeFiltersCount?: number;
  childrenWrapperClassName?: string;
  onResetFields?: any;
  filterHeight?: number;
} & HTMLAttributes<HTMLElement>;

function CollapsibleFilterSection({
  childrenWrapperClassName,
  className,
  children,
  activeFiltersCount,
  onResetFields,
  filterHeight,
  ...other
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { isFiltersExpanded, setIsFiltersExpanded } = useContext(
    SidebarFiltersContext
  );

  const accordionRef = useRef<HTMLDivElement>(null);
  const accordionHeight = useSidebarFiltersHeight(
    accordionRef,
    isFiltersExpanded,
    filterHeight
  );

  return (
    <section
      className={classNames(
        styles.sidebarItem,
        styles.expandedFilters,
        className,
        {
          [styles.disabledOverflow]: !isFiltersExpanded,
        }
      )}
      ref={accordionRef}
      style={{ height: accordionHeight }}
      {...other}
    >
      <h3
        className={classNames(styles.sidebarTitle, {
          [styles.expandedIcon]: isFiltersExpanded,
        })}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '8px',
            alignItems: 'start',
            position: 'absolute',
          }}
          onClick={() => {
            setIsFiltersExpanded(!isFiltersExpanded);
          }}
        >
          <i className="fa fa-filter p-text-secondary p-overlay-badge">
            {typeof activeFiltersCount !== 'undefined' &&
              activeFiltersCount > 0 && (
                <Badge
                  style={{ scale: '.9' }}
                  severity="danger"
                  value={activeFiltersCount}
                />
              )}
          </i>
          {t('Filters')}
        </div>
        <div
          className={styles.arrowIcon}
          onClick={() => {
            setIsFiltersExpanded(!isFiltersExpanded);
          }}
        >
          <span
            className={`${
              isFiltersExpanded ? 'fas fa-angle-up' : 'fas fa-angle-down'
            }`}
          />
        </div>
        <Button
          type="button"
          icon="fas fa-sync-alt"
          tooltip={t('Reset filters')}
          tooltipOptions={{ position: 'left' }}
          onClick={onResetFields}
          className={`p-button-text p-button-plain p-overlay-badge ${styles.resetButton}`}
        />
      </h3>

      <div className={classNames(childrenWrapperClassName)}>{children}</div>
    </section>
  );
}

export default CollapsibleFilterSection;
