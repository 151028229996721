import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import { debounceTimeout } from '../../../../../../utils/constants/misc';
import { bankPrefixes } from '../../../../../../utils/globals';
import { queryString } from '../../../../../../utils/helpers/http';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { isHeadquarter } from '../CreateEditDialog.functions';
import BankAccountField from './Fields/BankAccountField';
import CodBankAccountPaymentField from './Fields/CodBankAccountPaymentField';
import PersonalSubjectNoField from './Fields/PersonalSubjectNoField';
import PriceListField from './Fields/PriceListField';
import PriceListFieldRecipient from './Fields/PriceListFieldRecipient';
import WorkingHoursField from './Fields/WorkingHoursField';

function getBankName(value) {
  const bank = Object.keys(bankPrefixes).find((k) => k === value.slice(0, 3));

  return bank ? bankPrefixes[bank] : null;
}

function LegalDataTab({
  clientData,
  headquarterData,
  reloadHeadquarterData,
  isHeadquarterDataLoading,
  priceListOptions,
  arePriceListsLoading,
  isLegalEntitiesLoading,
  invoicingAndCODDepartmentOptions,
  isEditDialog,
  invoicingCheckboxProperties,
  fieldSettings,
}) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const debouncedImeClientsHQ = useDebounce(
    values.direkcija_ime,
    debounceTimeout
  );

  const onChangeHeadquarterName = useCallback(
    (e) => {
      setFieldValue('direkcija_ime', e);
    },
    [setFieldValue]
  );

  const onChangeHeadquarterId = useCallback(
    (e) => {
      setFieldValue('direkcija_id', e);

      setFieldValue(
        'departments',
        isEditDialog && clientData?.departments?.length > 0
          ? clientData.departments
          : [
              {
                id: null,
                name: 'Default',
                headquarters_id: null,
                charge_customer_id: 0,
              },
            ]
      );

      if (e === null || parseInt(clientData?.id) === e) {
        return;
      }

      reloadHeadquarterData({
        url: `/clients/${e}`,
        successCallback: (clientData) => {
          console.log('clientData', clientData);
          if (!!parseInt(clientData.cenovnik_direkcija)) {
            setFieldValue('cenovnik_id', clientData.cenovnik_id);
          }
          if (!!parseInt(clientData.maticen_broj)) {
            setFieldValue('maticen_broj', clientData.maticen_broj);
          }
          if (!!parseInt(clientData.danocen_broj)) {
            setFieldValue('danocen_broj', clientData.danocen_broj);
          }
        },
      });
    },
    [clientData, reloadHeadquarterData, isEditDialog, setFieldValue]
  );

  const { data: headquarters, isLoading: isHeadquartersLoading } = useAxiosHook(
    {
      url:
        '/clients/hq/ref' +
        queryString({ ime: debouncedImeClientsHQ, page: 1, limit: 40 }),
    },
    { skipWhen: debouncedImeClientsHQ === t('This client is a headquarter') }
  );

  const headQuarterOptions = useMemo(() => {
    const clientId =
      isEditDialog && clientData?.id ? parseInt(clientData.id) : 0;

    const defaultOption = [
      { label: t('This client is a headquarter'), value: clientId },
    ];

    const options =
      headquarters?.data
        ?.filter((hq) => hq.id !== clientId) // Exclude current client ID in edit mode
        .map((hq) => ({
          label: hq.ime,
          value: hq.id,
        })) || [];

    return [...defaultOption, ...options];
  }, [headquarters, clientData?.id, isEditDialog, t]);

  return (
    <div className="legal-data-tab p-fluid">
      <div className="p-grid">
        <div className="p-col-12 p-md-6">
          {values.pravno_lice && (
            <FieldWithErrors name="broj_dogovor" label={t('Contract No.')}>
              <Field
                as={InputText}
                name="broj_dogovor"
                id="broj_dogovor"
                maxLength="100"
                data-cy="contract_no"
              />
            </FieldWithErrors>
          )}
          <PersonalSubjectNoField
            label={t('Social Security No.')}
            maxLength="7"
          />
          {values.pravno_lice && (
            <FieldWithErrors name="danocen_broj" label={t('Tax No.')}>
              <Field
                as={InputText}
                name="danocen_broj"
                id="danocen_broj"
                tooltip={
                  !values.danocen_broj?.length || values.danocen_broj.length < 3
                    ? null
                    : getBankName(values.danocen_broj)
                }
                maxLength="15"
                onChange={(e) => {
                  setFieldValue(
                    'danocen_broj',
                    e.target.value.replace(/[^MKМК\d]/g, '')
                  );
                }}
                data-cy="tax_no"
              />
            </FieldWithErrors>
          )}
          <BankAccountField />
        </div>

        <div className="p-col-12 p-md-6">
          {values.pravno_lice && (
            <>
              <FieldWithErrors name="direkcija_id" label={t('Headquarter')}>
                <AutoCompleteInput
                  name="direkcija_id"
                  isLoading={isHeadquartersLoading}
                  placeholder={t('Search by name')}
                  options={headQuarterOptions}
                  filterValue={values?.direkcija_ime ?? ''}
                  onFilterChange={onChangeHeadquarterName}
                  onSelectionChange={onChangeHeadquarterId}
                  value={values?.direkcija_id}
                  filterDataCy="direkcija_id"
                  optionsClassName="data-cy-headquarter"
                />
              </FieldWithErrors>

              <FieldWithErrors name="rok_plakanje" label={t('Payment due')}>
                <Tooltip
                  target=".payment-due-tooltip"
                  position="left"
                  style={
                    invoicingCheckboxProperties.checked
                      ? { display: 'none' }
                      : {}
                  }
                />

                <div
                  className="payment-due-tooltip"
                  data-pr-tooltip={t('Invoicing is required')}
                >
                  <Field
                    as={InputText}
                    name="rok_plakanje"
                    id="rok_plakanje"
                    disabled={!invoicingCheckboxProperties.checked}
                    onChange={(e) => {
                      setFieldValue(
                        'rok_plakanje',
                        e.target.value.replace(/\D/g, '')
                      );
                    }}
                    data-cy="payment_due"
                  />
                </div>
              </FieldWithErrors>

              <WorkingHoursField />
            </>
          )}

          <PriceListField
            priceListOptions={priceListOptions}
            isHeadquarter={isHeadquarter}
            headquarterData={headquarterData}
            isHeadquarterDataLoading={isHeadquarterDataLoading}
            arePriceListsLoading={arePriceListsLoading}
          />

          <PriceListFieldRecipient
            priceListOptions={priceListOptions}
            arePriceListsLoading={arePriceListsLoading}
            isHeadquarter={isHeadquarter}
            headquarterData={headquarterData}
            isHeadquarterDataLoading={isHeadquarterDataLoading}
          />
        </div>
      </div>

      <span className="dialog-section-divider">{t('Settings')}</span>

      <div className="p-grid">
        <div className="p-col-12 p-md-6">
          {values.pravno_lice && (
            <FieldWithErrors name="faktura" label={false}>
              <Field
                name="faktura"
                inputId="faktura"
                as={Checkbox}
                {...invoicingCheckboxProperties}
                className="data-cy-invoicing"
              />

              <label htmlFor="faktura">{t('Invoicing')}</label>
            </FieldWithErrors>
          )}
          <CodBankAccountPaymentField />

          <FieldWithErrors name="cod_email_warrant" label={false}>
            <Field
              as={Checkbox}
              name="cod_email_warrant"
              inputId="cod_email_warrant"
              checked={values.cod_email_warrant}
            />

            <label htmlFor="cod_email_warrant">
              {t('Automated sending of COD warrant')}
            </label>
          </FieldWithErrors>
          {values.pravno_lice &&
            values.faktura &&
            fieldSettings.ConfigurableInvoiceSendingEmail && (
              <FieldWithErrors name="invoice_email_warrant" label={false}>
                <Field
                  as={Checkbox}
                  name="invoice_email_warrant"
                  inputId="invoice_email_warrant"
                  checked={values.invoice_email_warrant}
                />

                <label htmlFor="invoice_email_warrant">
                  {t('Automated sending invoice copy on email')}
                </label>
              </FieldWithErrors>
            )}
        </div>

        {values.pravno_lice &&
        (isEditDialog || (!isEditDialog && values.direkcija_id === 0)) ? (
          <div className="p-col-12 p-md-6">
            <FieldWithErrors
              name="cod_department_id"
              label={t('COD Department')}
            >
              <Field
                as={Dropdown}
                name="cod_department_id"
                inputId="cod_department_id"
                options={invoicingAndCODDepartmentOptions}
                className="data-cy-cod-department-id"
              />
            </FieldWithErrors>

            <FieldWithErrors
              name="invoice_department_id"
              label={t('Invoicing Department')}
            >
              <Field
                as={Dropdown}
                name="invoice_department_id"
                inputId="invoice_department_id"
                options={invoicingAndCODDepartmentOptions}
                className="data-cy-invoice-department-id"
              />
            </FieldWithErrors>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default LegalDataTab;
