import '../../Clients/Dialogs/CreateEditDialog/Tabs/Fields/NotificationsTab/NotificationsTab.scss';

import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import {
  getInitialValuesNotificationsCustomers,
  toApiDataNotificationsCustomers,
} from '../Settings.functions';
import NotificationsCustomersForm from './NotificationsCustomersForm';

function NotificationsCustomers() {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const {
    data: defaultNotificationsData,
    reload: defaultNotificationsReload,
    isLoading,
  } = useAxiosHook('/notifications/default');

  const {
    data: defaultNotificationsSubscriptionsData,
    reload: defaultNotificationsSubscriptionsReload,
    isLoading: defaultNotificationsSubscriptionsIsLoading,
  } = useAxiosHook('/notifications/default/subscriptions');

  const defaultNotificationsValues = useMemo(() => {
    const defaultValues = getInitialValuesNotificationsCustomers(
      t,
      defaultNotificationsData,
      defaultNotificationsSubscriptionsData
    );
    return defaultValues;
  }, [defaultNotificationsData, defaultNotificationsSubscriptionsData, t]);

  function handleFormSubmission() {
    const fields = toApiDataNotificationsCustomers(
      formRef.current.values,
      'default'
    );

    const fieldsSubs = toApiDataNotificationsCustomers(
      formRef.current.values,
      'subscriptions'
    );

    defaultNotificationsReload({
      method: 'POST',
      data: fields,
    });

    defaultNotificationsSubscriptionsReload({
      method: 'POST',
      data: fieldsSubs,
    });
  }

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={defaultNotificationsValues}
        enableReinitialize
        onSubmit={handleFormSubmission}
      >
        <Form>
          <NotificationsCustomersForm />
        </Form>
      </Formik>
      <div className="button-container">
        <Button
          type="button"
          label={
            isLoading || defaultNotificationsSubscriptionsIsLoading
              ? t('Loading...')
              : t('Save changes')
          }
          onClick={() => {
            formRef.current.handleSubmit();
          }}
          disabled={isLoading}
        />

        <Button
          type="button"
          icon="fas fa-sync-alt"
          className="p-button-outlined"
          tooltip={t('Reload settings')}
          onClick={() => {
            formRef.current.resetForm();
            defaultNotificationsReload();
            defaultNotificationsSubscriptionsReload();
          }}
        />
      </div>
    </>
  );
}

export default NotificationsCustomers;
