import '../AddEdit/AddEditDialog.scss';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { FormFields, Operators, RoleOptions } from './AddEditDialog.functions';

function RoleGroupsContext(): JSX.Element {
  const { getValues, control } = useFormContext<FormFields>();
  const watchRuleGroups = getValues('ruleGroups');
  const { append } = useFieldArray<FormFields>({
    name: 'ruleGroups',
  });

  const handleAddItem = () => {
    const newRuleGroup = {
      operator: 'OR',
      ordinalNumber: watchRuleGroups.length + 2,
      rules: [
        {
          rulePropertyId: undefined,
          ruleConditionId: undefined, // graterThenOrEqual
          value: '',
          ruleOperator: 'AND',
          ordinalNumber: 1,
        },
        {
          rolePropertyId: undefined,
          ruleConditionId: undefined, // lessThenOrEqual
          value: '',
          ruleOperator: 'OR', // this will be ignored since is the last item in the rule-set
          ordinalNumber: 2,
        },
      ],
    };
    append(newRuleGroup as any);
  };

  return (
    <>
      {Array.isArray(watchRuleGroups) &&
        watchRuleGroups?.map((ruleGroup, index: number) => {
          return (
            <div key={ruleGroup.ordinalNumber} className="rules-group">
              <Controller
                name={`ruleGroups.${index}.operator` as const}
                control={control}
                render={({ field }) => (
                  <Dropdown
                    options={RoleOptions}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {ruleGroup.rules.map((rule, i: number) => (
                <div key={rule.ordinalNumber} className="rules">
                  <Controller
                    name={
                      `ruleGroups.${index}.rules.${i}.rulePropertyId` as const
                    }
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        options={Operators}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          );
        })}
      <div className="btn-add">
        <Button
          label=""
          icon="fas fa-plus"
          className="main-btn"
          onClick={handleAddItem}
          data-cy="add-btn"
        />
      </div>
    </>
  );
}

export default RoleGroupsContext;
