import './ViewTerminalDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { SmartPOSTerminals } from '../../../../../types/api/smartPosTerminals';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import ViewTerminalContent from './ViewTerminalContent';

type Props = {
  visible: boolean;
  onHide: () => void;
  onEdit?: () => void;
  data: SmartPOSTerminals | any;
  isLoading: boolean;
};

function PreviewDialog({ visible, data, isLoading, onEdit, onHide }: Props) {
  const { t } = useTranslation();

  const dialogFooter = !isLoading && (
    <>
      {onEdit && (
        <Button label={t('Edit')} className="p-button-text" onClick={onEdit} />
      )}
      <Button label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={
        isLoading
          ? t('Loading...')
          : t('Terminal {{username}}', { username: data?.username ?? '' })
      }
      footer={dialogFooter}
      dismissableMask
      blockScroll
      maximizable
      maximized={isOnMobile}
      className="view-pos-terminal-dialog"
    >
      {isLoading ? <DialogSpinner /> : <ViewTerminalContent data={data} />}
    </Dialog>
  );
}

export default PreviewDialog;
