import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';

import SidebarItem from '../../../../../../Grid/Sidebar/SidebarItem';

type Props = {
  onSaveBtnClick: () => void;
  isSaveBtnDisabled: boolean;
  isSaveBtnVisible: boolean;
  onPrintBtnClick: () => void;
  isUpdateLoading: boolean;
};

function Actions({
  onSaveBtnClick,
  isSaveBtnDisabled,
  isSaveBtnVisible,
  onPrintBtnClick,
  isUpdateLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarItem template="actions">
      {isSaveBtnVisible &&
        (isUpdateLoading ? (
          <ProgressSpinner
            strokeWidth="3"
            style={{ width: '50px', height: '50px', marginBottom: '4px' }}
            className="dialog-progress-spinner"
          />
        ) : (
          <Button
            icon="fas fa-save"
            label={t('Save')}
            onClick={onSaveBtnClick}
            disabled={isSaveBtnDisabled}
            className="p-mb-2"
            tooltip={t('Save delivered/picked up shipments')}
            tooltipOptions={{ position: 'top' }}
          />
        ))}

      <Button
        icon="fa fa-print"
        label={t('Print')}
        onClick={onPrintBtnClick}
        className="p-button-secondary"
      />
    </SidebarItem>
  );
}

export default Actions;
