import { Field } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

import useAxiosHook from '../../../../hooks/useAxiosHook';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';

function SMS({ section }) {
  const { t } = useTranslation();

  const { data, isLoading } = useAxiosHook('/clients/sms/gateways');

  const smsGatewayOptions = [
    { label: t("Don't notify"), value: '' },
    { label: t('Default Provider'), value: 'default' },
    ...(Array.isArray(data) ? data : [])
      .filter((g) => typeof g === 'string')
      .map((g) => ({ label: g, value: g })),
  ];

  return (
    <div style={{ maxWidth: '480px' }}>
      <FieldWithErrors
        name={`${section}-DefaultGateway`}
        label={t('Default Gateway')}
      >
        <Field
          as={Dropdown}
          inputId={`${section}-DefaultGateway`}
          name={`${section}-DefaultGateway`}
          options={smsGatewayOptions}
          tooltip={t(
            'SMS provider used for sending SMS messages as notifications'
          )}
          disabled={isLoading}
          placeholder={isLoading ? t('Loading...') : null}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name={`${section}-NotificationsNumber`}
        label={t('Notifications Number')}
      >
        <Field
          as={InputText}
          id={`${section}-NotificationsNumber`}
          name={`${section}-NotificationsNumber`}
          tooltip={t(
            'Phone number used by the post for receiving SMS notifications'
          )}
        />
      </FieldWithErrors>
    </div>
  );
}

export default SMS;
